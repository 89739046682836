@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap");
@font-face {
  font-family: "Muthulais";
  src: url("./../Assets/Fonts/Muthulais/Muthualis.ttf");
  src: url("./../Assets/Fonts/Muthulais/Muthualis.woff");
}
.lgbtq {
  width: 100vw;
  background-image: url(https://ik.imagekit.io/future/assets/tr:f-auto/lgbtq.svg);
  background-repeat: repeat-x;
  animation: slide 50s linear infinite;
  height: 2.5px;
  position: fixed;
  bottom: 0;
  z-index: 9999;
  pointer-events: none;
}
@keyframes slide {
  0% {
    background-position-x: 0%;
  }
  50% {
    background-position: 2000%;
  }
  100% {
    background-position-x: 0%;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", sans-serif;
}
::selection {
  color: white;
  background: black;
}
img {
  image-rendering: -webkit-optimize-contrast !important;
}
.text-animate {
  visibility: hidden;
}
.flyet-bg-color {
  background-color: #23262f;
  padding: 20px;
}
@media (max-width: 1440px) {
  img {
    image-rendering: -webkit-optimize-contrast;
  }
  .container {
    max-width: 1140px !important;
  }
}
@media (min-width: 1400px) {
  img {
    image-rendering: -webkit-optimize-contrast;
  }
  .container {
    max-width: 1140px !important;
    margin: 0 auto;
    padding: 0 !important;
  }
}
@media (max-width: 1024px) {
  img {
    image-rendering: auto;
  }
  .container {
    max-width: 960px !important;
  }
}
@media (max-width: 992px) {
  .container {
    max-width: 960px !important;
  }
}
@media (max-width: 768px) {
  img {
    image-rendering: auto;
  }
  .container {
    max-width: 768px !important;
  }
}
/* @media (min-width: 1024px) {
  .container {
    max-width: 1140px !important;
    margin: 0 auto;
    padding: 0 !important;
  }
}
@media (min-width: 767px) {
  .container {
    max-width: 1140px !important;
  }
}
@media (max-width: 1024px) {
  .container {
    max-width: 1140px !important;
  }
} */

.border-bb {
  border-bottom: 1px solid #dee2e6 !important;
  padding-top: 10px;
  margin: 0 10px 10px;
}

/* --- Navbar --- */

.navbar {
  min-height: 65px;
}
.navbar-brand {
  margin-right: 0 !important;
}
.navbar-brand {
  margin-top: 0px !important;
}
.logo img {
  /* width: 83%; */
  height: auto;
  max-width: 100%;
  border: none;
  vertical-align: middle;
  display: inline-block;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.cont-detil {
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
  margin: 40px 0 0 0;
  padding: 40px 0 0;
  border-top: 2px solid #fff;
  text-align: left;
}
.cont-detil a {
  text-decoration: none;
  /* font-family: "Muthulais"; */
}

.header-btn {
  position: absolute;
  top: 14px;
  right: 52px;
}
.header-btn .footer__btn a {
  color: #000 !important;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  text-decoration: underline !important;
}

/* --- Footer --- */

.footer {
  position: relative;
  background-color: #000;
  margin-top: 80px;
  margin-bottom: 0px;
  padding: 120px 0px 120px 0px;
}
.foot-cont {
  text-align: center;
}
.foot-cont h2 {
  color: #ffffff;
  font-size: 40px;
  font-weight: 300;
  line-height: 48px;
}
.footer__btn a {
  color: #ffffff;
  font-size: 24px;
  font-weight: 300;
  text-decoration: underline;
  line-height: 40px;
}
.footer__btn a:hover {
  color: #ffffff;
}
.foot-li {
  padding: 10px;
  margin-top: 40px !important;
  margin-bottom: 20px;
}

.foot-li a {
  color: #2d2d2d;
  text-decoration: none;
}

.foot-li a:hover {
  color: #2d2d2d;
  text-decoration: none;
}

.foot-li h2 {
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
  padding-top: 8px;
}
.footer-addr {
  padding: 40px 10px 60px 10px;
}
.footer-addr h2 {
  color: #2d2d2d !important;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  text-decoration: none !important;
}
.footer-addr a {
  color: #2d2d2d !important;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  text-decoration: none !important;
}
.footer-addr h3 {
  color: #2d2d2d;
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
}
.footer-copy {
  padding: 10px;
}
.foot-copy h2 {
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: -0.02rem;
}
.foot-img {
  max-width: 25% !important;
}
.foot-img img {
  width: 100%;
}

.foot-form {
  margin-top: 117px;
  margin-bottom: 65px;
  padding: 10px !important;
}
.foot-form-cont {
  padding: 10px 22px !important;
}
.foot-form-cont h2 {
  color: #2d2d2d;
  font-size: 48px;
  font-weight: 300;
  line-height: 56px;
  letter-spacing: -0.02rem;
}
.foot-address {
  padding: 16px 20px !important;
}
.foot-addr {
  margin-bottom: 50px;
}
.foot-addr h2 {
  color: #2d2d2d;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.02rem;
  /* margin-bottom: 0; */
}
.foot-addr h3 {
  color: #2d2d2d;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 0;
}
.foot-addr:nth-child(1) h3 {
  color: #2d2d2d;
  font-size: 18px;
  font-weight: 300;
  line-height: 40px;
  margin-bottom: 0;
}
.foot-addr a {
  color: #2d2d2d;
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  letter-spacing: -0.02rem;
}
.foot-addr a:hover {
  color: #000;
}

.form-group {
  padding: 0px 172px 0px 0px;
}
.form-control {
  width: 100% !important;
  font-size: 17px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  color: rgb(0, 0, 0) !important;
  background-color: rgb(255, 255, 255) !important;
  padding: 13px 18px !important;
  border: 1px solid #e3e3e3 !important;
  border-radius: 0 !important;
  margin-top: 2rem !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea.form-control {
  padding: 10px 20px 60px !important;
}

button.form-control {
  padding: 10px 20px !important;
  margin-bottom: 0 !important;
}

.foot-li .col-lg-9,
.foot-li .col-lg-3 {
  display: flex;
  gap: 18px;
}

.foot-cont .footer__btn a {
  color: #fff !important;
}

/* -- Project works -- */

.proj-works {
  margin-top: 116px;
  margin-bottom: 40px;
}
.project_works {
  padding: 10px;
}
.project_works video {
  width: 95%;
}
.project_works h3 {
  color: #0c0d15;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: -0.02rem;
  padding-top: 10px;
}
.project_works h4 {
  color: #383838;
  font-size: 25px;
  font-weight: 300;
  line-height: 30px;
  padding-top: 10px;
  padding-right: 100px;
}
.project_works a {
  text-decoration: none !important;
}
.proj-works-top {
  padding-top: 200px;
}

/* -- OurClients -- */

.our-client {
  padding: 44px 20px 50px 20px;
  text-align: center;
}
.our-client h4 {
  color: #0c0d15;
  font-size: 32px;
  font-weight: 300;
  line-height: 48px;
}
.our-client p {
  color: #000000;
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
  padding-bottom: 30px;
}
.our-client img {
  width: 66%;
}

/* ----- About Page ----- */

.about-page {
  margin-top: 147px;
  margin-bottom: 80px;
}
.about-ban-cont {
  padding: 10px 10px 10px 22px !important;
}
.about-ban-cont h2 {
  color: #000000;
  font-size: 72px;
  font-weight: 200;
  line-height: 72px;
  letter-spacing: -0.02rem;
}
.about-ban-cont p {
  color: #2d2d2d;
  font-size: 24px;
  font-weight: 300;
  line-height: 40px;
  padding: 16px 20px 0 0;
  margin-bottom: 10px;
}
.about-ban-img {
  text-align: center;
  padding: 10px;
}
.about-ban-img img {
  width: 59%;
}

.about-bg {
  position: relative;
  margin-top: 80px;
  margin-bottom: 80px;
  padding: 120px 0px 120px 0px;
  background-image: url("https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/c9e08467-sgdgds-1.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.about-bg-cont {
  text-align: center;
}
.about-bg-cont p {
  color: #ffffff;
  font-size: 32px;
  font-weight: 300;
  line-height: 48px;
  padding: 10px 120px 10px 120px;
}

.aanim-cont h2 {
  color: #2d2d2d;
  font-family: "Muthulais", Sans-serif;
  font-size: 180px;
  font-weight: 400;
  text-align: center;
  letter-spacing: -0.02rem;
}

.aanim-cont p {
  color: #2d2d2d;
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: -0.02rem;
  text-align: center;
}

.aanim-img img {
  width: 100%;
  filter: brightness(100%) contrast(100%) saturate(0%) blur(0px)
    hue-rotate(0deg);
}

.aanim-creativ {
  margin-top: 80px;
  margin-bottom: 80px;
}
.aanim-creativ .col-lg-4 {
  padding: 10px;
}
.aanim-cre-cont {
  position: relative;
  text-align: right;
  margin: 0px 0px 0px 34px;
}
.aanim-cre-cont p {
  color: #2d2d2d;
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
}
.aanim-cre {
  position: relative;
  display: block;
}
.aanim-cre-rotate {
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.adj-positi {
  position: absolute;
  right: -320px;
  top: -75px;
}
.aanim-cre h2 {
  color: #2d2d2d;
  font-family: "Muthulais", Sans-serif;
  font-size: 144px;
  font-weight: 400;
  /* -ms-transform: rotate(-90deg);
  transform: rotate(-90deg); */
}
.aanim-cre-bord {
  border-bottom: 1px solid #000;
  width: 225px;
  margin: 0 auto;
  margin-left: 0;
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
}

.about-gallery {
  padding: 10px !important;
}
.about-gall {
  padding-top: 10px;
  padding-bottom: 10px;
}
.about-gall img {
  width: 100%;
  filter: brightness(100%) contrast(100%) saturate(0%) blur(0px)
    hue-rotate(0deg);
}

.bout-img img {
  filter: brightness(100%) contrast(100%) saturate(0%) blur(0px)
    hue-rotate(0deg);
}

.theboutsec {
  margin: 160px 0 40px 0;
}

.bout-con h2 {
  font-family: "Muthulais";
  font-size: 180px;
  font-weight: 400;
  letter-spacing: 8.5px;
  margin-left: 36px;
}

.bout-con span {
  color: #fff;
  font-family: "Muthulais";
  font-size: 180px;
  font-weight: 400;
  letter-spacing: 8.5px;
}

.bout-img {
  z-index: 0;
}

.bout-con {
  align-self: center;
  margin-bottom: -120px;
  z-index: 5;
}

.boxy {
  margin-top: 80px;
  border-top: 1px solid #000;
  padding-top: 10px;
  width: 263px;
}

.boxy p {
  color: #2d2d2d;
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
}

/* --- See Our thinking --- */

.sotia {
  margin-bottom: 60px;
  padding: 10px !important;
}
.seeourthinking video {
  width: 100%;
}
.sotia h2 {
  text-align: center;
  color: #0c0d15;
  font-size: 32px;
  font-weight: 300;
  line-height: 48px;
  letter-spacing: -0.02rem;
  margin-bottom: 40px;
}
.seeourthinking h3 {
  color: #0c0d15;
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 30px;
  letter-spacing: 0.4px;
  margin-top: 15px;
  margin-bottom: 0px;
}
.seeourthinking h4 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 300;
  margin-bottom: 40px;
}
.seeourthinking a {
  text-decoration: none;
  color: #000000;
}
.seeourthinking a:hover {
  color: #000000;
}

/* --- Work Pages --- */

.works {
  margin-top: 115px;
  margin-bottom: 25px;
}
.work-ban-cont {
  padding: 10px !important;
}
.work-ban-cont h2 {
  color: #2d2d2d;
  font-size: 35px;
  font-weight: 300;
  line-height: 45px;
  letter-spacing: -0.02rem;
  margin-bottom: 3px;
}
.works-padd {
  padding: 10px !important;
}
.work_pages a {
  color: #000;
  text-decoration: none !important;
}
.work_pages a:hover {
  color: #000;
}
.work_pages h3 {
  color: #0c0d15;
  font-size: 17px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 30px;
  letter-spacing: 0.4px;
  margin-top: 12px;
  margin-bottom: 0px;
}
.work_pages h4 {
  font-size: 26px;
  font-weight: 300;
  line-height: 30px;
  margin-bottom: 0px;
  padding: 0px 110px 50px 0px;
}

/* --- Services page --- */
.services {
  position: relative;
  margin-top: 100px;
  margin-bottom: 92px;
}
.serv-bann {
  padding: 10px 12px !important;
}
.serv-bann-cont {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  padding: 10px !important;
}
.serv-bann-cont h2 {
  color: #2d2d2d;
  font-size: 32px;
  font-weight: 300;
  line-height: 48px;
  padding-right: 30px;
}
.serv-bann-cont video {
  width: 100%;
}
.serv-bg {
  background-color: #000000;
  position: relative;
  padding-top: 56px;
  padding-bottom: 140px;
}
.serv-brand-overview h2 {
  color: #ffffff;
  font-size: 32px;
  font-weight: 300;
  line-height: 48px;
  text-align: center;
}
.serv-brand-overview p {
  color: #ffffff;
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
  text-align: center;
  margin: 0px 260px 0px 260px;
  padding: 10px;
}
.serv-brand {
  margin-top: 140px !important;
  margin-bottom: 0px;
  padding: 10px !important;
}
.serv-brand-cont {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}
.serv-brand-cont video {
  width: 100%;
}
.serv-brand-cont h4 {
  color: #ffffff;
  font-size: 32px;
  font-weight: 300;
  line-height: 48px;
  padding: 0% 10% 0% 10%;
}
.serv-brand-cont p {
  color: #ffffff;
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
  padding: 0% 10% 0% 10%;
}
.serv-capblt {
  margin-top: 32px;
  margin-bottom: 32px;
  padding: 10px;
}
.serv-capblt h2 {
  color: #2d2d2d;
  font-size: 32px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: -0.02rem;
}
.serv-capb-cont {
  padding: 10px;
}
.serv-capb-cont h2 {
  color: #2d2d2d;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.02rem;
}
.serv-capb-cont p {
  color: #969696;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.02rem;
}

li.aai {
  list-style-type: none;
  display: inline-block;
  margin: 0em 0em 1em 0em;
  width: 150px;
}

a.aa {
  color: #2d2d2d;
  font-size: 18px;
  font-weight: 500;
  padding: 12px;
  /* text-decoration: none; */
}

a.aa:hover {
  color: #2d2d2d;
  text-decoration: none;
  border: 2px solid #ffffff;
  /* border-radius: 20px; */
  padding: 10px;
}

.filter-gallery {
  position: relative;
  top: 2em;
  text-align: left;
}

.feat,
.brand,
.camp,
.web,
.digi,
.pack,
.social,
.film {
  transition-timing-function: ease;
  transition-duration: 1s;
}
.filter-all.active::after {
  color: #703535 !important;
}

.dropdown-menu {
  /* top: 200px !important; */
  transform: translate3d(12px, 38px, 0px) !important;
}

.vapho-video1 {
  margin-top: 30px;
  padding: 10px;
}

.aniva {
  padding: 32px 100px 32px 100px;
  font-size: 18px;
  line-height: 32px;
  font-weight: 300;
}

.aniva-bg {
  background: #fdf5cd;
  text-align: center;
  margin: 80px 0px;
}

.sbbread {
  padding: 32px 150px 32px 150px;
  font-size: 18px;
  line-height: 32px;
  font-weight: 300;
}

/*------ E-wallet ------*/
.ewallet-img-container {
  height: 800px;
  background-color: #23262f;
  position: relative;
  z-index: -1;
}

.ewallet-img-container img {
  position: absolute;
  top: 200px;
}

.ewallet-absolute-img {
  margin-bottom: -200px;
  z-index: 1;
  position: relative;
}

/* ----- Vinveli ----- */

.vinveli-bg {
  background-image: url("https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/a8c894f1-fdsrame-1.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: -200px;
  margin-bottom: 0px;
  min-height: 600px;
  width: 100%;
  position: relative;
}
.vinveli-over {
  margin-top: 70px;
  margin-bottom: 32px;
}
.vinv-over {
  padding: 10px !important;
}
.vinv-over h2 {
  color: #2d2d2d;
  font-size: 32px;
  font-weight: 300;
  line-height: 48px;
  letter-spacing: -0.02rem;
  text-align: right;
}
.vinv-over p {
  color: #2d2d2d;
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
  padding: 0 75px 0 15px;
}
.vinv-video video {
  width: 100%;
  padding: 10px;
}
.vinv-image {
  padding: 44px 10px 20px 10px;
}
.vinv-cont {
  margin: 50px 100px;
  text-align: center;
}
.vinv-cont h2 {
  color: #2d2d2d;
  font-size: 32px;
  font-weight: 300;
  line-height: 48px;
  letter-spacing: -0.02rem;
}
.vinv-cont p {
  color: #2d2d2d;
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
  padding: 0 50px;
}
.vinv-note {
  position: relative;
  background-color: #f3f3f3;
}
.vinv-note-cont {
  display: flex;
  justify-content: center;
  align-items: center;
}
.vinv-note-cont p {
  color: #2d2d2d;
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
}
.vinv-note-img img {
  padding: 10px;
}
.vinveli-bg1 {
  background-image: url("https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/bb7e0d56-group-f391-1.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -80px;
  position: relative;
  min-height: 500px;
}
.vinv-pisto {
  position: relative;
  background-color: #165c81;
}
.vinv-pistol img {
  width: 1000px;
  max-width: 1000px;
  margin: -550px 0px 100px -50px;
}
.vinv-pisto-cont {
  padding: 10px;
}
.vinv-pisto-cont p {
  color: #ffffff;
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
  margin: 50px 100px 0px 0px;
}
.vinv-pistol-img {
  margin-top: -220px;
  padding: 10px !important;
}
.vinv-pisto-img {
  z-index: 999;
}
.vinv-pisto-img:nth-child(1) {
  text-align: right !important;
}
.vinv-pisto-para {
  margin-top: 60px;
  margin-bottom: 80px;
  padding: 10px !important;
}
.vinv-pisto-para p {
  color: #2d2d2d;
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
  text-align: center;
  padding: 0 180px;
}
.vinv-social {
  padding: 10px;
}
.vinv-social video {
  position: relative;
  max-width: 100%;
  width: 100%;
  margin: 0;
  line-height: 1;
  border: none;
  object-fit: cover;
  padding: 0px 100px 60px 100px;
  overflow: hidden;
}
.vinv-social img {
  position: relative;
  height: auto;
  max-width: 100%;
  border: none;
  border-radius: 0;
  box-shadow: none;
  line-height: 0;
  margin-top: 140px;
  padding: 0px 100px 0px 100px !important;
}

/* ---------- Freshry -----------*/

.freshey-overv {
  margin-top: 40px;
  padding: 10px !important;
}
.freshey-bann-img {
  margin-top: 32px;
  padding: 10px;
}
.freshey-img img {
  width: 25%;
  margin: 20px 0 40px 0;
}
.freshey-img:nth-child(3) img {
  width: 83%;
  margin: 0;
}
.freshey-para p {
  color: #2d2d2d;
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
  padding: 0px 150px !important;
}
.freshey-para:nth-child(4) p {
  color: #2d2d2d;
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
  margin: 80px 0 30px 0;
  padding: 0px 150px !important;
}
.freshey-prod-img {
  padding: 10px !important;
  margin-bottom: 20px;
}
.freshey-prod-img img {
  margin-bottom: 8px;
}

/* -------- Intellect --------*/

.intellect-bann-img {
  padding: 10px !important;
}
.intellect-bann-img img {
  margin-top: 32px;
  background-color: #000;
}
.intellect-bg {
  position: relative;
  background-image: url("https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/071c7ccf-intellect-bg-1.png");
  background-position: bottom right;
  background-repeat: no-repeat;
}
.intellect-over {
  margin: 80px 0 !important;
  padding: 10px;
}
.intellect-over-cont p {
  color: #2d2d2d;
  font-size: 24px;
  font-weight: 300;
  line-height: 40px;
  margin: 120px 55px 0px 0px;
}
.intellect-para p {
  color: #2d2d2d;
  font-size: 24px;
  font-weight: 300;
  line-height: 40px;
  text-align: center;
  margin-top: 50px;
  padding: 0 120px;
}
.intellect-img {
  margin-top: 40px !important;
}
.intellect-img img {
  padding: 10px !important;
}
.intellect-patt-bg {
  position: relative;
  background-image: url(https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/057456b8-intellect-annualreport-cs61.jpg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 500px;
  width: 100%;
}

/* --------- Bayleaf -----------*/

.bayleaf-ban-img {
  margin-top: 34px;
  padding: 10px;
}
.bayleaf-over-cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}
.bayleaf-over-cont p {
  color: #000000;
  font-size: 22px;
  font-weight: 400;
  font-style: normal;
  line-height: 40px;
  padding-right: 30px;
}
.bayleaf-over-img {
  float: right;
}
.bayleaf-social {
  padding: 10px !important;
}
.bayleaf-social img {
  width: 70%;
}
.bayleaf-social:nth-child(1) {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}
.bayleaf-social:nth-child(3) {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}
.bayleaf-social:nth-child(1) img {
  float: right;
}
.bayleaf-social:nth-child(3) img {
  float: right;
}
.bayleaf-social:nth-child(2) img {
  margin: 225px 0px 0px 10px;
}
.bayleaf-social:nth-child(4) img {
  margin: 225px 0px 0px 10px;
}
.bayleaf-bg {
  position: relative;
  background-color: #006f75;
  margin-top: 70px;
  margin-bottom: 0px;
}
.bayleaf-cont {
  padding: 10px;
}
.bayleaf-cont p {
  color: #ffffff;
  font-size: 22px;
  font-weight: 400;
  font-style: normal;
  line-height: 40px;
  padding: 70px 335px 0px 0px;
}
.bayleaf-cont ul {
  margin: 22px 0 230px 0 !important;
  padding-left: 20px !important;
}
.bayleaf-cont li {
  text-align: left;
  color: #ffffff;
  font-size: 22px;
  font-weight: 400;
  line-height: 40px;
}
.bayleaf-imgs {
  margin-top: -140px !important;
  padding: 10px !important;
  z-index: 999;
}
.bayleaf-img {
  padding: 10px !important;
  z-index: 999;
}

/* ------ Writers Cafe --------*/

.writercafe-bann {
  position: relative;
  overflow: hidden;
}
.writercafe-bann img {
  margin-top: -60px !important;
  padding: 10px !important;
}
.writer-cafe {
  margin-top: -70px !important;
  margin-bottom: 40px;
  padding: 10px;
}
.writercafe-img {
  padding: 10px !important;
}
.writercafe-bg {
  background-color: #cfde4a;
  position: relative;
}
.writercafe-cont {
  position: relative;
  z-index: 999;
}
.writercafe-cont h2 {
  position: relative;
  color: #2d2d2d;
  font-size: 32px;
  font-weight: 300;
  line-height: 48px;
  padding-top: 70px;
  padding-bottom: 20px;
}
.writercafe-cont p {
  color: #2d2d2d;
  font-size: 24px;
  font-weight: 300;
  line-height: 40px;
}
.writercafe-bg img {
  margin-top: -150px;
}
.writer-cafe-img {
  height: 387px !important;
}
.para-cont p {
  color: #2d2d2d;
  font-size: 24px;
  font-weight: 300;
  line-height: 40px;
  text-align: center;
  padding: 70px 0px 30px 0px;
}
.para-cont img {
  width: 100%;
}

/* ----------- Asian Paints ---------*/

.asian-slide {
  margin-top: 70px;
}

/* .asian .carousel{
  padding-bottom: 0 !important;
  padding-top: 0 !important;
} */
.asian-slide-marg {
  margin-left: 22px !important;
  height: 440px;
}
.asianpaint-para p {
  color: #2d2d2d;
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
  padding-left: 75px !important;
}
.asian-paint-para {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  padding: 0 40px 0 60px !important;
}
.asian-paint-para p,
li {
  color: #2d2d2d;
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
}
.asian-paint-para ul {
  padding-top: 20px;
  padding-left: 22px;
}
.asian-img {
  padding: 10px !important;
}
.asian-wdth:nth-child(1) img {
  max-width: 108% !important;
}
.asian-wdth:nth-child(2) img {
  max-width: 95.4% !important;
  margin-left: 32px;
}
.asian-slide-carousel0 {
  padding: 10px !important;
  height: 910px;
}
.asian-slide-carousel {
  padding: 0 !important;
  height: 666px;
}
.asian-des-txt h4 {
  color: #2d2d2d;
  font-size: 24px;
  font-weight: 300;
  line-height: 40px;
  padding: 10px;
  margin-top: 40px !important;
}
.asian-img-design {
  padding: 10px !important;
  max-width: 85% !important;
}
.asian-img-design0 {
  padding: 10px !important;
  max-width: 107% !important;
  margin-left: -50px;
}

/* ---------- L exotique ---------*/

.lexotique-cont p {
  color: #2d2d2d;
  font-size: 24px;
  font-weight: 300;
  line-height: 40px;
  letter-spacing: -0.02rem;
  padding: 50px 0 0;
}
.lexotique-video {
  width: 100%;
  padding: 10px;
}
.lexotique-bg {
  position: relative;
  background-color: #12609f;
  margin-top: -40px;
  z-index: -1;
}
.lexotique-bg-para p {
  color: #ffffff;
  font-size: 24px;
  font-weight: 300;
  line-height: 40px;
  letter-spacing: -0.02rem;
  text-align: center;
  padding: 80px 130px 70px 130px;
}

/* ----------- Mehta Jewellery --------*/

.mehta-jewell-cont {
  padding: 40px 100px !important;
}
.mehta-jewell-cont p {
  color: #2d2d2d;
  font-size: 24px;
  font-weight: 300;
  line-height: 40px;
  text-align: center;
}
.mehta-per {
  padding: 35px 0 24px 0;
}
.mehta-per p {
  color: #2d2d2d;
  font-size: 24px;
  font-weight: 300;
  line-height: 40px;
  text-align: center;
}
.mehta-per span {
  font-weight: 500;
}
.mehta-bg {
  position: relative;
  background-image: url("https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/497609df-set3_diamond-copdy-1-1.png");
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -106px;
  margin-bottom: 30px;
  padding: 70px 0 84px 0;
}
.mehta-bg-img {
  text-align: center;
}
.mehta-bg-img img {
  width: 50%;
}
.mehta-bg-cont {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}
.mehta-bg-cont img {
  width: 32%;
  margin-left: -40px;
  padding-bottom: 10px;
}
.mehta-bg-cont p {
  color: #ffffff;
  font-size: 24px;
  font-weight: 300;
  line-height: 40px;
  letter-spacing: -0.02rem;
  padding-right: 20px;
}

/* --------- Curiohh ---------*/

.curiohh-over {
  margin-top: 60px;
  padding: 20px !important;
}
.curiohh-over .col-lg-6 {
  padding: 10px !important;
}
.curiohh-cont p {
  color: #2d2d2d;
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
  padding-right: 113px;
  margin-bottom: 0;
}
.curiohh-para {
  margin-top: 74px;
}
.curiohh-para p {
  color: #2d2d2d;
  font-size: 24px;
  font-weight: 300;
  line-height: 40px;
}
.marg-top {
  margin-top: 60px;
}
.curiohh-img-width {
  width: 82%;
}
.curiohh-img0 {
  margin-top: 39px !important;
  margin-left: -20px !important;
  padding: 5px !important;
}

/* ---------- Waycool -----------*/

.waycool-bann {
  position: relative;
  margin-top: 14.5px;
  padding: 12px !important;
}
.waycool-bann-cont {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  padding: 10px !important;
}
.waycool-bann-cont h2 {
  color: #2d2d2d;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.02rem;
}
.waycool-bann-cont h3 {
  color: #2d2d2d;
  font-size: 40px;
  font-weight: 300;
  line-height: 48px;
  letter-spacing: -0.02rem;
}
.waycool-bann-img {
  width: 111.3%;
  margin-top: 49.5px;
  margin-left: -63px;
}
.waycool-over {
  position: relative;
  margin: 100px 0;
}
.waycool-over-cont {
  position: relative;
  padding: 10px !important;
}
.waycool-over-cont h3 {
  color: #2d2d2d;
  font-size: 40px;
  font-weight: 300;
  line-height: 48px;
  letter-spacing: -0.02rem;
}
.waycool-over-cont p {
  color: #2d2d2d;
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: -0.02rem;
  margin-bottom: 0;
  padding-right: 25px;
}
.waycool-bg {
  position: relative;
  background-image: url(https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/5d3f5c8b-bg-13waycool-1.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: 422px;
}
.waycool-bg-cont {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.waycool-bg-cont p {
  color: #ffffff;
  font-size: 24px;
  font-weight: 300;
  line-height: 40px;
  text-align: center;
  letter-spacing: -0.02rem;
  padding: 0 100px;
}
.waycool-brand {
  margin-top: 60px !important;
  padding: 10px !important;
}
.waycool-brand h4 {
  color: #2d2d2d;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.5px;
}
.waycool-brand p {
  color: #000000;
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
  padding-right: 100px;
  margin-bottom: 0;
}
.waycool-brand li {
  color: #2d2d2d;
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
  padding-right: 100px;
}
.waycool-brand ul {
  padding-left: 20px;
}
.build ul {
  padding-left: 20px;
  list-style-type: decimal;
}
.waycool-brand .col-md-6 {
  padding: 10px !important;
}
.waycool-bg-strat {
  position: relative;
  background-color: #004870;
  margin-top: 32px;
  margin-bottom: 0px;
  padding: 30px 0px 50px 0px;
}
.waycool-strat-cont {
  padding: 10px !important;
}
.waycool-strat-cont h4 {
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.5px;
}
.waycool-strat-cont p {
  color: #ffffff;
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
  margin-bottom: 0;
  padding: 0 200px 80px 0;
}
.waycool-strat-cont h3 {
  color: #ffffff;
  font-family: "Playfair Display", Sans-serif;
  font-size: 48px;
  font-weight: 400;
  line-height: 69px;
  letter-spacing: -0.02rem;
  text-align: center;
  margin-bottom: 0;
  padding: 32px 18px;
}
.waycool-img img {
  max-width: 69%;
}
.waycool-bg-flo {
  position: relative;
  background-color: #004870;
  margin-top: 75px;
  margin-bottom: 0px;
}
.waycool-flo-cont .waycool-flo,
.waycool-flo {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 10px !important;
}
.waycool-flo-cont img {
  padding: 70px 0;
}
.waycool-flo-cont p {
  color: #ffffff;
  font-size: 24px;
  font-weight: 300;
  line-height: 40px;
  letter-spacing: -0.02rem;
  padding-right: 100px;
}

/* ------------ Abbys Page -----------*/

.abbys-cont {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  padding: 10px 100px !important;
}
.abbys-cont h4 {
  color: #2d2d2d;
  font-size: 32px;
  font-weight: 300;
  line-height: 48px;
  letter-spacing: -0.02rem;
}
.abbys-cont p {
  color: #2d2d2d;
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: -0.02rem;
}
.wdth {
  width: 42.999% !important;
}
.wdth0 {
  width: 56.999% !important;
}

/* ------------ Vinita Hospital page ----------*/
.vinita-alpha {
  margin-top: 80px;
  padding: 10px !important;
}
.vinita-cont {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 10px !important;
}
.vinita-cont p {
  color: #2d2d2d;
  font-size: 24px;
  font-weight: 300;
  line-height: 40px;
  letter-spacing: -0.02rem;
  margin-bottom: 0;
  padding: 0 50px;
}
.vinita-z {
  z-index: 999;
}
.vinita-resp {
  display: flex;
  align-items: center;
  justify-content: center;
}
.vinita-bg {
  position: relative;
  background-color: #d9f3f9;
  margin-top: -115px !important;
  margin-bottom: 0px;
}
.vinita-bg-cont {
  padding: 150px 150px 0px 150px !important;
}
.vinita-bg-cont p {
  color: #2d2d2d;
  font-size: 24px;
  font-weight: 300;
  line-height: 40px;
  text-align: center;
}
.vinita-img {
  padding: 10px !important;
  text-align: center;
}
.vinita-img img {
  margin-top: 50px;
  padding: 10px !important;
}
.vinita-img-bg {
  padding-bottom: 220px !important;
}
.vinita-bg0 {
  position: relative;
  background-color: #1b5796;
}
.vinita-bg0-img img {
  margin-top: -150px !important;
  padding: 10px !important;
}
.vinita-img0-para h4 {
  color: #ffffff;
  font-size: 32px;
  font-weight: 300;
  line-height: 48px;
  text-align: left;
  padding: 0 120px 0 30px;
}
.vinita-img0-para {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
}
.vinita-padd {
  padding-bottom: 230px !important;
}
.vinita-img0-para p {
  color: #ffffff;
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
  text-align: left;
  padding: 0 120px 0 30px;
}
.vinita-padd0 {
  margin: 10px 0 30px 0 !important;
  padding: 40px 0 100px 0 !important;
}
.vinita-tit h4 {
  color: #ffffff;
  font-size: 32px;
  font-weight: 300;
  line-height: 48px;
  text-align: center;
  padding-bottom: 20px;
}
.vinita-chrt {
  margin-top: -150px !important;
  padding: 10px !important;
}
.vinita-chrt-img {
  padding: 10px !important;
}

/* ------------ Desi Di page -----------*/

.desi-cont {
  margin-top: 32px;
  margin-bottom: 32px;
}
.desi-cont p {
  color: #2d2d2d;
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
  text-align: center;
  padding: 0px 100px 0px 100px;
}
.desidi-img {
  width: 19%;
  margin: 40px 0 20px 0;
  padding: 10px !important;
}
.desi-margi {
  margin: 20px 0;
}
.desi-di-rest {
  margin-top: 40px !important;
  margin-bottom: 22px !important;
}
.desi-di-media {
  position: relative;
  margin-top: 42px;
  margin-bottom: 42px;
}
.desi-head h4 {
  color: #2d2d2d;
  font-size: 32px;
  font-weight: 300;
  line-height: 48px;
  text-align: center;
  padding-bottom: 20px;
}
.desi-rest-wdt {
  padding: 0 !important;
}
.desi-rest-wdt:nth-child(2) {
  width: 50.9%;
}
.desi-rest-wdt:nth-child(3) {
  width: 49.1%;
}
.desi-img {
  padding: 10px !important;
}
.desi-img:nth-child(3) {
  margin-top: 50px !important;
}
.desi-di-img {
  padding: 0 !important;
}
.desi-di-img img {
  box-shadow: 4px 4px 14px -8px rgb(0 0 0 / 50%);
}
.desi-di-img:nth-child(2) img {
  margin-top: 100px !important;
}
.desi-di-lists ul {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.desi-di-lists li {
  margin-left: 40px;
  padding-left: 0px !important;
}
.desi-di-lists li::marker {
  color: #000 !important;
  font-size: 28px;
}

/* ------------- Kitchen Ji Page -----------*/

.kitchen-cont {
  margin-top: 32px !important;
  padding: 10px !important;
}
.kitchen-cont h4 {
  color: #2d2d2d;
  font-size: 40px;
  font-weight: 300;
  line-height: 48px;
  text-align: center;
}
.kitchen-cont p {
  color: #2d2d2d;
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
  text-align: center;
  margin-bottom: 0;
}
.kitchen-ji-cont {
  margin-top: 60px !important;
  padding: 10px !important;
  text-align: center;
}
.kitchen-ji-cont p {
  color: #2d2d2d;
  font-size: 24px;
  font-weight: 300;
  line-height: 40px;
  letter-spacing: -0.02rem;
}
.kitchen-ji-cont img {
  width: 60%;
  padding: 10px;
}

/* ----------- Curiohh Travel Page ----------- */

.curiohh-trvl-bg {
  position: relative;
  background-color: #04dec5;
  margin-top: 50px;
  margin-bottom: 0px;
  padding: 0px 0px 0px 0px;
}
.curiohh-trvl {
  margin-top: 54px !important;
}

.curiohh-trvl2 {
  margin-top: 54px !important;
}

.curiohh-trvl3 {
  margin-top: 54px !important;
}

.curiohh-trvl-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px !important;
}
.curiohh-trvl-cont p {
  color: #2d2d2d;
  font-size: 24px;
  font-weight: 300;
  line-height: 40px;
  letter-spacing: -0.02rem;
}
.curiohh-trvl-cont:nth-child(1) p {
  padding: 0 80px 0 0;
}
.curiohh-trvl-cont:nth-child(2) p {
  padding: 0 60px 0 30px;
}
.curiohh-cont h4 {
  color: #2d2d2d;
  font-size: 32px;
  font-weight: 300;
  line-height: 48px;
  letter-spacing: -0.02rem;
}

/* ----------- Amelies Page -----------*/
.amelie-resp {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* -------- -------- */

.errors {
  color: red;
}

.careers .cont {
  align-self: center;
}

.careers .cont h1 {
  font-size: 48px;
  font-weight: 300;
  line-height: 56px;
}

.careers .cont p {
  font-size: 24px;
  font-weight: 300;
  line-height: 40px;
  padding-right: 90px;
}

.careers img {
  filter: brightness(100%) contrast(100%) saturate(0%) blur(0px)
    hue-rotate(0deg);
}

.opp {
  margin-bottom: 80px;
  padding: 10px !important;
}

.opp h2 {
  text-align: center;
  font-size: 32px;
  font-weight: 300;
  padding-bottom: 20px;
  margin: 0;
}

.opp-box {
  padding: 30px 0px !important;
}

.opp h3 {
  color: #000;
  font-size: 25px;
  font-weight: 300;
}

.opp a {
  text-decoration: none;
}

.opportunities h2 {
  font-size: 35px;
  font-weight: 500;
  line-height: 45px;
}
.opportunities h4 {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  padding-bottom: 12px;
}
.opportunities h5 {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}
.opportunities p {
  color: #383838;
  font-size: 18px;
  font-weight: 300;
  line-height: 30px;
}

.opportunities .career-box {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.career-box-a {
  background-color: #f3f3f3;
  padding: 35px 50px !important;
  margin-bottom: -80px;
}

.career-box-a h2 {
  margin-top: 20px;
  font-size: 48px;
  font-weight: 300;
  line-height: 56px;
}

.opportunities .career-box1 {
  margin-top: 100px !important;
  padding-bottom: 50px !important;
}

/* .career-box-a .form-control{
  padding: 1.25rem !important;
} */

.career-box-a button {
  text-transform: uppercase;
  background-color: #000 !important;
  color: #fff !important;
  font-weight: 600 !important;
}

.resume {
  margin-top: 25px !important;
}
.form-bdr {
  border-radius: 4px !important;
}
.filefom {
  font-size: 15px;
}

/* Sunnybee Honey */
.sunnybee-bg-color {
  background-image: url("https://ik.imagekit.io/future/assets/tr:f-auto/sunnybee-img-bg-1.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.hon-sec2 {
  padding: 50px 0px !important;
}

.cont-honey {
  align-self: center;
}

.cont-honey h2 {
  font-size: 24px;
  font-weight: 300;
  line-height: 40px;
}

.hon-sec3 {
  text-align: center;
  background-color: #ffc923;
  margin-top: 150px;
  margin-bottom: 30px;
}

.hon-sec3 img {
  max-width: 80%;
  margin-top: -180px;
}

.hon-sec4 {
  text-align: center;
  margin-bottom: 20px;
}

.hon-sec4 h2 {
  font-size: 24px;
  font-weight: 300;
  line-height: 40px;
  margin-bottom: 20px;
}

.hon-sec5 {
  margin-bottom: 20px;
}

.year {
  text-align: center;
  align-self: center;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
}

.year2 img {
  max-width: 80%;
}

.year3 img {
  max-width: 80%;
}

.brand-from-to {
  margin-top: 40px !important;
  margin-bottom: 20px;
}

.brand-from-to h6 {
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
}

.brand-from-to h5 {
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
}

.cont-brand {
  text-align: right;
  align-self: center;
}

.cont-brand h3 {
  font-size: 32px;
  font-weight: 300;
  line-height: 48px;
}

.cont-brand h5 {
  font-size: 24px;
  font-weight: 300;
  line-height: 40px;
}

.padd-lft {
  padding: 0px !important;
}
.marg-rl {
  margin: 10px 0 10px 0 !important;
}
.title h2 {
  margin-bottom: 25px !important;
}
.title p {
  padding-right: 40px;
  padding-bottom: 40px;
}
.title ul {
  padding-left: 22px !important;
}
.desc {
  margin-top: 40px !important;
}
.bg-color {
  background-color: transparent !important;
  border: 1px solid #f3f3f3 !important;
}
.bg-opport {
  background-color: #f3f3f3;
  padding-top: 25px !important;
  padding-bottom: 25px !important;
  margin-bottom: -80px;
  margin-top: 40px;
}
[type="file"]::-webkit-file-upload-button {
  background: #d4d4d4 !important;
  font-size: 14px;
}

.swal-button {
  background-color: #000 !important;
  color: #fff;
  border: none;
  box-shadow: none;
  border-radius: 0px !important;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 30px;
  margin: 0;
  cursor: pointer;
}

.swal-button:not([disabled]):hover {
  background-color: #000 !important;
  color: #fff !important;
}

/* ------------------------------------------------------
------------------------------------------------------*/

/* .filter-cat {
  margin: 1em 0;
}

.f-cat {
  color: #fff;
  padding: 1em;
  border: 5px solid #fff;
}

.f-cat[data-cat=cat-red] {
  background: #bbbbbb;
}

.f-cat[data-cat=cat-green] {
  background: #747474;
}

.f-cat[data-cat=cat-blue] {
  background: #414141;
}

.filter-cat-results .f-cat {
  opacity: 0;
  display: none;
}
.filter-cat-results .f-cat.active {
  opacity: 1;
  display: block;
  -webkit-animation: fadeIn 0.65s ease forwards;
  animation: fadeIn 0.65s ease forwards;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
} */

.navbar {
  position: fixed;
  width: 100%;
  background-color: #fff;
  padding: 0 !important;
}

.nav-container {
  height: 65px;
}

.navbar .menu-items {
  display: flex;
}

.navbar .nav-container li {
  list-style: none;
}

.navbar .nav-container a {
  text-decoration: none;
  color: #fff;
}

.nav-container {
  display: block;
  position: relative;
  height: 65px;
}

.nav-container .checkbox {
  position: absolute;
  display: block;
  height: 32px;
  width: 32px;
  top: 14px;
  right: 0px;
  z-index: 5;
  opacity: 0;
  cursor: pointer;
}

.nav-container .hamburger-lines {
  display: block;
  height: 19px;
  width: 25px;
  position: absolute;
  top: 20px;
  right: 0px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.nav-container .hamburger-lines .line {
  display: block;
  height: 2px;
  width: 100%;
  border-radius: 10px;
  background: #0e2431;
}

.nav-container .hamburger-lines .line1 {
  transform-origin: 0% 0%;
  transition: transform 0.4s ease-in-out;
}

.nav-container .hamburger-lines .line2 {
  transition: transform 0.2s ease-in-out;
  width: 70%;
  margin-left: 7px;
}

.nav-container .hamburger-lines .line3 {
  transform-origin: 0% 100%;
  transition: transform 0.4s ease-in-out;
}

.navbar .menu-items {
  background: #000;
  height: 100vh;
  width: 50%;
  transform: translate(200%);
  display: flex;
  flex-direction: column;
  margin-left: -40px;
  padding: 80px;
  transition: transform 0.8s ease-in-out;
  margin-right: -222px !important;
}

.navbar .menu-items li {
  box-sizing: border-box;
  color: #fff;
  display: inline-block;
  font-size: 42px;
  font-weight: 300;
  letter-spacing: 1.2px;
  line-height: 66px;
  padding: 10px 0;
  position: relative;
  text-decoration: none;
  text-transform: lowercase;
  width: 100%;
}

.logo {
  position: absolute;
  top: 12px;
  left: 0px;
}

.nav-container input[type="checkbox"]:checked ~ .menu-items {
  transform: translateX(0);
}

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
  transform: rotate(45deg);
  background: #fff;
}

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
  transform: scaleY(0);
}

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
  transform: rotate(-45deg);
  background: #fff;
}

.nav-container input[type="checkbox"]:checked ~ .logo {
  display: none;
}
/* .site-logo {
  position: relative;
} */

/* .site-content {
  max-width: 1100px;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.site-content__headline {
  font-weight: 200;
  color: #000000;
  font-size: calc(2vw + 10px);
} */

/* input {
  position: fixed;
  opacity: 0;
}

label {
  position: absolute;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
}
label::before {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(10, 38, 57, 0.75);
  content: "";
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
label .burger {
  position: fixed;
  top: 1.2em;
  right: 14em;
  z-index: 3;
  width: 1.5em;
  height: 1em;
  margin: 0;
  padding: 0;
  transition: opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
label .burger::before,
label .burger .bar, label .burger::after {
  position: absolute;
  right: 0;
  display: block;
  width: 100%;
  height: 12%;
  background: black;
  content: "";
  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
label .burger .bar {
  top: 44%;
}
label .burger::before {
  top: 0;
  transform-origin: top left;
}
label .burger::after {
  bottom: 0;
  transform-origin: bottom left;
}

input:focus + label .burger,
label:hover .burger {
  opacity: 0.75;
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  transform: translate3d(0, 0, 0);
  material acceleration
  transform: translateY(100%);
  will-change: transform;
  transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1);
}
nav::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 25em;
  background: #000000;
  content: "";
  transform: skewX(15deg) translateY(100%);
  transform-origin: bottom right;
  will-change: transform;
  transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1);
}
@media (min-width: 40em) {
  nav::before {
    width: 100em;
  }
}
nav a {
  margin: 0.5em 0;
  padding: 0.2em 2em;
  font-size: 1.5em;
  color: white;
  text-decoration: none;
  font-weight: 500;
  transform: translateX(80em);
  transition: color 0.15s, transform 0.5s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
nav a:nth-child(1) {
  transition-delay: 0s, 100ms;
}
nav a:nth-child(2) {
  transition-delay: 0s, 150ms;
}
nav a:nth-child(3) {
  transition-delay: 0s, 200ms;
}
nav a:nth-child(4) {
  transition-delay: 0s, 250ms;
}
nav a:nth-child(5) {
  transition-delay: 0s, 300ms;
}
nav a:nth-child(6) {
  transition-delay: 0s, 350ms;
}
nav a:hover, nav a:focus {
  color: black;
}

main {
  overflow: hidden;
}
main .content {
  transform: translate3d(0, 0, 0);
  material acceleration
  will-change: transform, filter;
  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

[id=navcheck]:checked + label::before {
  opacity: 1;
  pointer-events: auto;
}
[id=navcheck]:checked + label .burger::before, [id=navcheck]:checked + label .burger::after {
  width: 141.42%;
}
[id=navcheck]:checked + label .burger::before {
  transform: rotate(45deg) translateY(-50%);
  background-color: #fff;
  width: 2em;
  top: -0.29em;
}
[id=navcheck]:checked + label .burger::after {
  transform: rotate(-45deg) translateY(50%);
  background-color: #fff;
  width: 2em;
  top: 1em;
}
[id=navcheck]:checked + label .burger .bar {
  transform: scale(0.1);
}
[id=navcheck]:checked ~ nav {
  transform: translateX(0);
}
[id=navcheck]:checked ~ nav::before {
  transform: skewX(0deg) translateX(0);
}
[id=navcheck]:checked ~ nav a {
  transform: translateX(45em);
}
[id=navcheck]:checked ~ main .content {
  transform: translateX(3em);
  transform-origin: left center;
  filter: blur(2px);
}

helper
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  bottom: 75px;
  right: 715px; */
/* -ms-transform: rotate(20deg);
  transform: rotate(20deg); */
/* }
.fb-icon, .insta-icon, .linkedin-icon {
  border: 1px solid #fff;
  padding: 5px 12px;
  margin: 0px 15px 0px 0px;
  border-radius: 20px;
}
.site-logo {
  margin-top: -6px;
} 


  width: 1px;
} */
/* .opp-form p{
  font-size: 17px !important;
    font-weight: 400 !important;
    line-height: 22px !important;
    color: rgb(0, 0, 0) !important;
} */

.tq-pg {
  font-family: "Manrope", sans-serif;
  position: relative;
  margin-top: 216px;
  margin-bottom: 145px;
  padding: 0 12px !important;
}
.tq-pg-cont {
  font-family: "Manrope", sans-serif;
  text-align: center;
  padding: 20px 0 !important;
}
.tq-pg-cont h5 {
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: 54px;
  line-height: 59px;
  text-align: center;
  letter-spacing: -0.03em;
  color: #000000;
}
@media (max-width: 767px) {
  .tq-pg {
    font-family: "Manrope", sans-serif;
    position: relative;
    margin-top: 176px;
    margin-bottom: 145px;
    padding: 0 12px !important;
  }
  .tq-pg-cont h5 {
    font-family: "Manrope", sans-serif;
    font-weight: 700;
    font-size: 38px;
    line-height: 50px;
    text-align: center;
    letter-spacing: -0.03em;
    color: #000000;
    padding: 0 10px;
  }
  .tq-pg-cont p {
    font-family: "Manrope", sans-serif;
    font-size: 16px !important;
    margin-bottom: 30px !important;
  }
}
.tq-pg-cont p a {
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  text-align: center;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  color: #000;
  padding: 0;
  margin-bottom: 47px;
}
.tq-pg__btn a {
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  text-decoration-line: underline;
  color: #1f1f1f;
}

.tq-pg__btn a:hover {
  color: #1f1f1f;
}

/* Drop Down */

.form-control-input {
  margin-bottom: 15px;
}
.form-control-input-g label {
  margin-left: 10px;
}
.form-control-input-g h5 {
  margin-bottom: 20px;
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  color: rgb(0, 0, 0) !important;
  opacity: 1;
}

.form-control-input-g {
  width: 100% !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  color: rgb(0, 0, 0) !important;
  opacity: 0.7;
  background-color: rgb(255, 255, 255) !important;
  padding: 0px 0px !important;
  border: 0px solid #e3e3e3 !important;
  border-radius: 0 !important;
  margin-top: 2rem !important;
}
