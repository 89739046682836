@media (max-width: 1440px) {
  .contain ul.toggle {
    right: 11.5%;
  }
}

@media only screen and (min-device-width: 1601px) and (max-device-width: 2560px) {
  .navbar .menu-items {
    margin-right: -34% !important;
  }
  /* .nav-container .checkbox {
    top: 34px;
    right: -265px;
  }
  
  .nav-container .hamburger-lines {
    top: 40px;
    right: -265px;
  } */
  .nav-img img {
    bottom: 75px;
    right: 874px !important;
  }
}

@media (max-width: 1024px) {
  .contain ul.toggle {
    right: 6%;
  }
}

@media only screen and (min-device-width: 1274px) and (max-device-width: 1440px) {
  .navbar .menu-items {
    margin-right: -160px !important;
  }
}
@media only screen and (min-device-width: 1024px) and (max-device-width: 1275px) {
  .navbar .menu-items {
    margin-right: -82px !important;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1160px) {
  .nav-container {
    margin: 0 12px !important;
  }
}

/* ------------------------------------------------------------------------------------------------
-------------------------------------------   TAB -------------------------------------------------
-----------------------------------------------------------------------------------------------  */

@media (max-width: 1024px) {
  .opp-form {
    padding-left: 0px;
    padding-right: 0px;
  }

  /* --- Header navbar --- */
  .navbar {
    min-height: 70px;
  }
  .navbar-brand {
    margin-top: 0 !important;
  }
  .logo img {
    width: 84% !important;
  }
  .navbar-brand {
    margin-left: 0.8rem;
  }

  .contain {
    position: relative;
    width: 100%;
  }
  .contain .section {
    position: relative;
    width: 100%;
    height: 500px;
  }
  .contain .section:nth-of-type(1) {
    background: #95a5a6;
  }
  .contain .section:nth-of-type(2) {
    background: #7f8c8d;
  }

  /* *********ul.toogle******** */
  .contain ul.toggle {
    position: fixed;
    top: 3.4%;
    right: 5%;
    width: 42px;
    height: 27px;
    cursor: pointer;
    z-index: 99999;
  }
  .contain ul.toggle li {
    position: absolute;
    left: 75%;
    transform: translateY(-50%);
    height: 2px;
    width: 60%;
    list-style: none;
    background: #000;
    transition: 0.9s;
  }
  .contain ul.toggle li:nth-of-type(1) {
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    background: #fff !important;
  }
  .contain ul.toggle li:nth-of-type(2) {
    left: -100%;
    opacity: 0;
  }
  .contain ul.toggle li:nth-of-type(3) {
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
    background: #fff !important;
  }
  .contain ul.toggle.active li:nth-of-type(1) {
    top: 20%;
    transform: translateY(0) rotate(180deg);
    background: #000 !important;
  }
  .contain ul.toggle.active li:nth-of-type(2) {
    top: 53%;
    left: 38px;
    width: 18px;
    opacity: 10;
  }
  .contain ul.toggle.active li:nth-of-type(3) {
    top: 79%;
    transform: translateY(0) rotate(180deg);
    background: #000 !important;
  }

  /******Sidebar******/
  .contain .sidebar {
    position: fixed;
    top: 0;
    right: -65%;
    background: #000;
    width: 65%;
    height: 100vh;
    box-sizing: border-box;
    padding: 50px 40px;
    transition: 0.9s;
    z-index: 4;
  }
  .contain .sidebar.active {
    right: 0;
    z-index: 9999;
  }
  .contain .sidebar ul.menu {
    position: relative;
    width: 100%;
    margin-top: 20px;
  }
  .contain .sidebar ul.menu li {
    list-style: none;
  }
  .contain .sidebar ul.menu li a {
    display: inline-block;
    position: relative;
    text-decoration: none;
    color: #fff;
    width: 100%;
    padding: 10px 0;
    box-sizing: border-box;
    font-size: 42px;
    font-weight: 300;
    line-height: 66px;
    letter-spacing: 1.2px;
  }

  /* --- Home banner --- */

  .banner__content {
    display: flex;
    align-items: center;
    margin-top: 180px;
    margin-bottom: 120px;
  }
  .banner__text {
    padding: 11px 10px 10px 18px;
  }
  .banner__text h1 {
    color: #000000;
    font-size: 40px;
    font-weight: 300;
    line-height: 1.4em;
    letter-spacing: -0.02rem;
    padding: 0 25px 0 0;
  }
  .banner__btn a {
    color: #000000;
    font-size: 16px;
    text-decoration: underline !important;
    line-height: 22px;
  }
  .banner__btn a:hover {
    color: #000;
  }

  /* --- overview video ---*/
  .overview {
    position: relative;
    object-fit: cover;
    visibility: inherit;
    opacity: 1;
    transform: translate(0px, 0px);
    background-color: transparent;
    background-image: linear-gradient(180deg, #ffffff 7%, #000000 7%);
    /* z-index: -999; */
  }
  .overview video {
    max-width: 100%;
    /* width: 1120px; */
    /* height: 400px; */
    margin: 0;
    line-height: 1;
    border: none;
    padding: 10px;
  }
  .overview-cont h2 {
    color: #ffffff;
    font-family: "Source Sans Pro", Sans-serif;
    font-size: 24px;
    font-weight: 300;
    line-height: 40px;
    padding: 20px 10px 80px 10px;
  }

  /* ---- Footer ----- */
  .foot-form {
    margin-top: 110px;
    margin-bottom: 0px;
    padding: 20px !important;
  }
  .foot-form-cont h2 {
    color: #2d2d2d;
    font-size: 48px;
    font-weight: 300;
    line-height: 56px;
    letter-spacing: -0.02rem;
    padding-left: 12px;
  }
  .form-group {
    padding: 0px 20px 0px 0px;
  }
  .errors {
    color: red;
    margin-left: 10px;
  }
  .form-control {
    margin-left: 10px;
  }
  .foot-address {
    padding: 32px 30px 0px 30px !important;
  }
  .foot-addr:nth-child(1) h3 {
    color: #2d2d2d;
    font-size: 18px;
    font-weight: 300;
    line-height: 40px;
    margin-bottom: 0;
    padding-right: 60px;
  }
  .footer {
    position: relative;
    background-color: #000;
    margin-top: 80px;
    margin-bottom: 0px;
    padding: 82px 0px 50px 0px;
  }
  .foot-cont {
    text-align: center;
  }
  .foot-cont h2 {
    color: #ffffff;
    font-size: 40px;
    font-weight: 300;
    line-height: 48px;
  }
  .footer__btn a {
    color: #ffffff !important;
    font-size: 24px;
    font-weight: 300;
    text-decoration: underline !important;
    line-height: 40px;
  }
  .footer__btn a:hover {
    color: #ffffff;
  }
  .foot-li {
    padding: 20px;
    margin-top: 20px !important;
    margin-bottom: 15px;
  }
  .foot-li h2 {
    font-size: 18px;
    font-weight: 300;
    line-height: 32px;
    padding-top: 8px;
  }
  .footer-addr {
    padding: 40px 20px 60px 20px;
  }
  .footer-addr h2 {
    color: #2d2d2d !important;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    text-decoration: none !important;
  }
  .footer-addr a {
    color: #2d2d2d !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    text-decoration: none !important;
  }
  .footer-addr h3 {
    color: #2d2d2d;
    font-size: 18px;
    font-weight: 300;
    line-height: 32px;
  }
  .footer-copy {
    padding: 15px;
  }
  .foot-copy h2 {
    font-size: 16px;
    font-weight: 300;
    line-height: 32px;
    letter-spacing: -0.02rem;
  }
  .foot-img {
    max-width: 22% !important;
  }
  .foot-img img {
    width: 100%;
  }

  /* -- Project works -- */
  .proj-works {
    margin-top: 116px;
    margin-bottom: 40px;
    padding: 15px 5px 15px 20px;
  }
  .project_works {
    padding: 0px;
  }
  .project_works video {
    width: 95%;
  }
  .project_works h3 {
    color: #0c0d15;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 1;
    letter-spacing: -0.02rem;
    padding-top: 10px;
  }
  .project_works h4 {
    color: #383838;
    font-size: 25px;
    font-weight: 300;
    line-height: 30px;
    padding-top: 10px;
    padding-right: 100px;
  }
  .project_works a {
    text-decoration: none !important;
  }
  .proj-works-top {
    padding-top: 200px;
  }

  /* -- OurClients -- */
  .our-client {
    padding: 44px 20px 50px 20px;
    text-align: center;
  }
  .our-client h4 {
    color: #0c0d15;
    font-size: 32px;
    font-weight: 300;
    line-height: 48px;
  }
  .our-client p {
    color: #000000;
    font-size: 18px;
    font-weight: 300;
    line-height: 32px;
    padding-bottom: 30px;
  }
  .our-client img {
    width: 66%;
  }

  /* --- See Our thinking --- */
  .sotia {
    margin-bottom: 60px;
    padding: 10px 30px !important;
  }

  /* ------------------------
            About Page 
    ------------------------ */

  .about-page {
    margin-top: 87px;
    margin-bottom: 15px;
    padding: 0 4px;
  }
  .about-ban-cont {
    padding: 10px 10px 10px 22px !important;
  }
  .about-ban-cont h2 {
    color: #2d2d2d;
    font-size: 60px;
    font-weight: 300;
    line-height: 64px;
    letter-spacing: -0.02rem;
  }
  .about-ban-cont p {
    color: #2d2d2d;
    font-size: 18px;
    font-weight: 300;
    line-height: 32px;
    padding: 13px 120px 0 5px;
    margin-bottom: 10px;
  }
  .about-ban-img img {
    width: 88%;
  }
  .about-bg-cont p {
    color: #ffffff;
    font-size: 32px;
    font-weight: 300;
    line-height: 48px;
    padding: 10px;
  }
  .about-bg {
    position: relative;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 50px 0px 50px 0px;
  }

  .aanim-cont h2 {
    color: #2d2d2d;
    font-family: "Muthulais", Sans-serif;
    font-size: 120px;
    font-weight: 400;
    text-align: center;
    letter-spacing: -0.02rem;
  }
  .aanim-cont p {
    color: #2d2d2d;
    font-size: 18px;
    font-weight: 300;
    line-height: 32px;
    letter-spacing: -0.02rem;
    text-align: center;
    margin-bottom: 0;
  }
  .aanim-creativ {
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 0 30px 0 40px !important;
  }
  .aanim-cre-resp h2 {
    color: #2d2d2d;
    font-family: "Muthulais", Sans-serif;
    font-size: 52px;
    font-weight: 300;
    line-height: 1;
    letter-spacing: -0.02rem;
  }
  .aanim-cre-resp p {
    color: #2d2d2d;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: -0.02rem;
    padding: 0;
  }

  /* ------------------------
            Work Page 
    ------------------------ */

  .work-ban-cont h2 {
    padding-left: 10px;
  }
  .work_pages h4 {
    padding: 0px 0px 50px 0px;
  }

  /* ------------------------
            Service Page 
    ------------------------ */

  .services {
    position: relative;
    margin-top: 100px;
    margin-bottom: 40px;
    padding: 0 10px;
  }
  .serv-bann-cont h2 {
    padding-right: 12px;
    padding-bottom: 20px;
  }
  .serv-bg {
    padding-top: 36px;
    padding-bottom: 30px;
  }
  .serv-brand-overview h2 {
    padding-left: 10px;
  }
  .serv-brand-overview p {
    margin: 0px 30px 0px 30px;
    padding: 0 10px;
  }
  .serv-brand {
    margin-top: 40px !important;
    margin-bottom: 0px;
    padding: 10px !important;
  }
  .serv-brand-cont h4 {
    padding: 5% 0% 0% 0%;
  }
  .serv-brand-cont p {
    padding: 0% 0% 0% 0%;
  }
  .serv-capblt {
    margin-top: 32px;
    margin-bottom: 0px;
    padding: 10px;
  }

  /* --------- my persona ----------*/

  .my-perso-head {
    margin-top: 140px;
    padding: 0 8px;
  }
  .my-perso-head h2 {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.3px;
    padding: 0 10px;
  }
  .my-perso-head h3 {
    font-size: 32px;
    font-weight: 300;
    line-height: 40px;
    letter-spacing: -0.3px;
    padding: 0 100px 0 10px;
  }
  .my-perso-img {
    padding: 40px 20px 0 20px;
  }
  .my-perso-obj {
    margin: 70px 18px 40px 18px;
  }
  .my-perso-obj-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 20px;
  }
  .my-perso-obj-cont p {
    color: #000;
    font-size: 16px;
    font-weight: 200;
    /* letter-spacing: 1.5px; */
    line-height: 32px;
    padding-top: 10px;
    padding-right: 25px;
  }
  .my-perso-res {
    margin: 100px 18px 40px 18px;
  }
  .my-perso-res-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 20px;
  }
  .my-perso-res-cont p {
    color: #000;
    font-size: 16px;
    font-weight: 200;
    /* letter-spacing: 1.5px; */
    line-height: 32px;
    padding-top: 10px;
    padding-right: 20px;
  }
  .my-perso-res-img {
    padding: 50px 30px 10px 30px;
  }
  .my-perso-ul {
    display: flex;
    margin-top: 40px;
  }
  .my-perso-li {
    padding-left: 20px !important;
  }
  .my-perso-li li {
    color: #000;
    font-size: 16px;
    font-weight: 300;
    line-height: 36px;
    margin-right: 70px;
  }
  .my-perso-li li::marker {
    font-size: 20px;
  }
  .my-perso-wf {
    margin: 80px 18px 40px 18px;
  }
  .my-perso-wf-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 40px;
  }
  .my-perso-img-wf {
    margin-top: 40px;
    margin-left: 0 !important;
  }
  .my-perso-ill {
    margin: 100px 18px 40px 18px;
  }
  .my-perso-ill-cont {
    padding-bottom: 20px !important;
  }
  .my-perso-ill-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 40px;
  }
  .my-perso-ill-cont p {
    color: #000;
    font-size: 16px;
    font-weight: 200;
    line-height: 32px;
    padding-top: 20px;
  }
  .my-perso-ill-video {
    max-height: 35vh !important;
    margin-bottom: 40px;
    overflow: hidden;
  }
  .img-ill {
    margin-top: 10px;
  }
  .my-persona-img-ill-0 {
    background-color: #f0f0f0;
    margin-left: 0;
    margin-bottom: 245px;
  }
  .img-ill-0 {
    padding: 20px 0 0px 0;
  }
  .img-ill-01 {
    margin: 70px 0px -235px 0px;
  }
  .aniva {
    padding: 32px 20px 32px 20px;
    text-align: left;
  }
  .aniva-bg {
    margin: 40px 0px;
  }
  .sbbread {
    padding: 32px 20px 32px 20px;
    text-align: left;
  }
  /* ----- Vinveli-Web page ----- */

  .vinveli-head {
    margin-top: 130px;
    padding: 0 20px;
  }
  .vinveli-head-cont {
    padding: 10px !important;
  }
  .vinveli-head-cont h2 {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02rem;
  }
  .vinveli-head-cont h3 {
    font-size: 30px;
    font-weight: 300;
    line-height: 40px;
    letter-spacing: -0.02rem;
    padding-right: 20px;
  }
  .vinveli-img {
    margin-top: 30px;
    padding: 0 18px !important;
  }

  .vinveli-obj {
    margin-top: 37px;
    margin-bottom: 40px;
    padding: 0 30px !important;
  }
  .vinveli-obj-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 20px;
  }
  .vinveli-obj-cont p {
    color: #000000;
    font-size: 16px;
    font-weight: 200;
    line-height: 32px;
    padding-right: 40px;
  }

  .vinveli-res {
    margin: 70px 0 10px 0;
    padding: 0 18px;
  }
  .vinveli-res-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 20px;
  }
  .vinveli-res-cont p {
    color: #000;
    font-size: 16px;
    font-weight: 200;
    /* letter-spacing: 1.5px; */
    line-height: 32px;
    padding-top: 10px;
    padding-right: 10px;
  }

  .vinveli-ul {
    display: flex;
    margin-top: 40px;
  }
  .vinveli-li {
    padding-left: 20px !important;
  }
  .vinveli-li li {
    color: #000;
    font-size: 16px;
    font-weight: 300;
    line-height: 36px;
  }
  .vinveli-li li::marker {
    font-size: 20px;
  }

  .vinveli-desin {
    margin-top: 80px;
    margin-bottom: 20px;
  }
  .vinveli-desin {
    padding: 0 18px;
  }
  .vinveli-desin-text h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 30px;
  }
  .vinveli-desin-ul h5 {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
  }
  .vinveli-desin-ul {
    padding: 0 6px 0 12px !important;
  }
  .vinveli-desin-li {
    padding-left: 20px !important;
  }
  .vinveli-desin-li li {
    color: #000000;
    font-size: 16px;
    font-weight: 200;
    line-height: 34px;
  }
  .vinveli-desin-li li::marker {
    font-size: 20px !important;
  }

  .vinveli-wf {
    margin: 80px 0 40px 0;
    padding: 0 18px !important;
  }
  .vinveli-wf-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 30px;
  }
  .vinveli-wf-img {
    margin-top: 30px;
    padding: 0 !important;
  }

  .vinveli-bg-img {
    background-image: url("https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/90ceaf7f-3v-bg.png");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: 40px;
    margin-bottom: 0px;
    position: relative;
  }
  .vinveli-cov-img {
    width: 69%;
  }
  .vinveli-bg-img0 {
    background-color: #07212b;
    margin-top: 50px;
    margin-bottom: 0px;
    position: relative;
  }
  .vinveli-cov-img0 {
    padding: 44px 20px 44px 20px !important;
  }
  .vinveli-cov-img1 {
    margin-top: 40px;
    margin-bottom: 0px;
  }
  .vinveli-bg-img2 {
    background-image: url("https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/90ceaf7f-3v-bg.png");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 40px;
    margin-bottom: 0px;
    position: relative;
  }

  /* ------------- Vinveli -------------*/

  .vinveli-bg {
    margin-top: -200px;
    min-height: 420px;
  }
  .vinv-over p {
    color: #2d2d2d;
    font-size: 18px;
    font-weight: 300;
    line-height: 32px;
    padding: 0;
  }
  .vinv-video video {
    width: 100%;
    padding: 10px 18px;
  }
  .vinv-image {
    padding: 20px 18px;
  }
  .vinv-cont {
    margin: 20px 0px;
    padding: 0 18px !important;
    text-align: left;
  }
  .vinv-cont h2 {
    font-size: 29px;
    line-height: 40px;
  }
  .vinv-cont p {
    padding: 0;
  }
  .vinv-note {
    padding: 0 8px;
  }
  .vinv-note-cont p {
    padding: 10px 2px 10px 10px;
  }
  .vinv-note-img {
    margin-top: 60px;
    padding: 0 !important;
  }
  .vinveli-bg1 {
    margin-top: 0px;
    min-height: 300px;
  }
  .vinv-pistol {
    width: 100%;
    position: relative;
  }
  .vinv-pistol img {
    width: 200%;
    max-width: 100%;
    margin: -200px 0px -100px 12px;
  }
  .vinv-pisto-cont {
    padding: 10px 30px 20px 30px !important;
  }
  .vinv-pisto-cont p {
    font-size: 18px;
    margin: 50px 0px 0px 0px;
  }
  .vinv-pistol-img {
    margin-top: 0px;
    padding: 20px !important;
  }
  .vinv-pisto-para {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px 20px !important;
  }
  .vinv-pisto-para p {
    text-align: left;
    padding: 0 10px;
  }
  .vinv-social {
    padding: 10px;
  }
  .vinv-social video {
    padding: 0px 18px 10px 18px;
  }
  .vinv-social img {
    margin-top: 0px;
    padding: 0px 18px !important;
  }

  /* --------- Freshry ---------*/

  .freshey-img img {
    width: 30%;
    margin: 0px 0 40px 0;
  }
  .freshey-para p {
    padding: 0px 20px !important;
  }
  .freshey-para:nth-child(4) p {
    margin: 20px 0 30px 0;
    padding: 0px 20px !important;
  }

  /* -------- Intellect --------*/

  .intellect-over {
    margin: 50px 0 !important;
    padding: 10px;
  }
  .intellect-over-cont p {
    margin: 0px;
  }
  .intellect-para p {
    padding: 0 40px;
  }
  .intellect-patt-bg {
    min-height: 290px;
  }

  /* --------- Bayleaf -----------*/
  .bayleaf-ban-img {
    margin-top: 15px;
    padding: 10px;
  }
  .bayleaf-over-cont p {
    font-size: 18px;
    line-height: 32px;
    padding-right: 12px;
  }
  .bayleaf-social:nth-child(2) img {
    margin: 0px 0px 0px 10px;
  }
  .bayleaf-social:nth-child(4) img {
    margin: 0px 0px 0px 10px;
  }
  .bayleaf-cont p {
    font-size: 18px;
    line-height: 32px;
    padding: 20px 8px 0 8px;
  }
  .bayleaf-cont ul {
    margin: 12px 0 180px 0 !important;
    padding-left: 28px !important;
  }
  .bayleaf-cont li {
    text-align: left;
    color: #ffffff;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
  }

  /* ------ Writers Cafe page --------*/
  .writercafe-cont h2 {
    padding-top: 60px;
    padding-bottom: 10px;
  }
  .writercafe-cont p {
    padding: 0 50px;
  }
  .writer-cafe-img {
    height: 253px !important;
  }

  /* ----------- Asian Paints page ---------*/

  /* ---------- L exotique page ------------*/

  .lexotique-cont p {
    padding: 50px 20px 0 20px;
  }
  .lexotique-bg-para p {
    padding: 60px 50px 50px;
  }

  /* ----------- Mehta Jewellery page ----------*/

  .mehta-jewell-cont {
    padding: 30px 20px !important;
  }
  .mehta-bg-img img {
    width: 60%;
  }
  .mehta-bg-cont img {
    width: 50%;
    margin-left: -40px;
    margin-top: 0px;
    padding-bottom: 0px;
  }
  .mehta-bg-cont p {
    font-size: 16px;
    font-weight: 300;
    line-height: 32px;
    letter-spacing: -0.02rem;
    padding-right: 20px;
  }

  /* --------- Curiohh page ---------*/

  .curiohh-over {
    margin-top: 30px;
    padding: 0 22px !important;
  }
  .curiohh-cont p {
    padding-right: 30px;
    padding-left: 10px;
  }

  .curiohh-img0 {
    margin-top: 22px !important;
    margin-left: -20px !important;
    padding: 5px !important;
  }

  /* ---------- Waycool page -----------*/

  .waycool-bann {
    margin-top: 45px;
    padding: 30px !important;
  }
  .waycool-bann-cont h3 {
    font-size: 30px;
    line-height: 40px;
    padding-right: 40px;
  }
  .waycool-over {
    margin: 75px 0;
    padding: 0 20px !important;
  }
  .waycool-over-cont h3 {
    font-size: 30px;
    padding-right: 32px;
  }
  .waycool-brand {
    margin-top: 40px !important;
    padding: 20px !important;
  }
  .waycool-brand p {
    padding-right: 22px;
  }
  .waycool-brand li {
    padding-right: 20px;
  }
  .waycool-bg-strat {
    position: relative;
    background-color: #004870;
    margin-top: 32px;
    margin-bottom: 0px;
    padding: 30px 20px;
  }
  .waycool-strat-cont {
    padding: 10px !important;
  }
  .waycool-strat-cont p {
    padding: 0 20px 20px 0;
  }
  .waycool-strat-cont h3 {
    font-size: 40px;
  }
  .build {
    padding: 0 30px !important;
  }
  .waycool-flo-cont p {
    padding-right: 20px;
  }

  /* ------------ Abbys Page -----------*/

  .abbys-cont {
    padding: 10px !important;
  }
  .wdth {
    width: 43.2% !important;
  }
  .wdth0 {
    width: 56.7% !important;
  }

  /* ------------ Vinita Page -----------*/

  /* ------------ Desi Di page -----------*/
  .desi-cont p {
    padding: 0px 30px 0px 30px;
  }
  .desi-wdt {
    width: 50% !important;
  }
  .desidi-img {
    width: 30%;
    margin: 10px 0 20px 0;
  }

  /* ------------- Kitchen Ji Page -----------*/
  .kitchen-ji-cont {
    margin-top: 10px !important;
  }

  /* ----------- Amelies Page -----------*/
  .amelie-resp {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .opportunities .career-box1 {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }
  .career-box-a {
    padding-left: 20px !important;
    padding-right: 20px !important;
    margin-bottom: 0px;
  }
  .marg-lft {
    margin-left: 0px !important;
  }
  .bg-opport {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .careers {
    padding: 0 22px !important;
  }
  .title p {
    padding-right: 0px;
  }

  /*-------------------------------------
    ----------- Navbar Menu ------------*/

  .logo {
    position: absolute;
    top: 12px;
    left: 12px;
  }
  .nav-container {
    padding: 0px !important;
    position: relative;
    height: 70px !important;
  }
  .nav-container .checkbox {
    top: 10px;
    right: 20px;
  }
  .nav-container .hamburger-lines {
    height: 18px;
    width: 25px;
    top: 17px;
    right: 20px;
  }
  .navbar .menu-items {
    background: #000;
    height: 100vh;
    width: 70%;
    transform: translate(180%);
    padding: 50px 100px 50px 50px;
    transition: transform 0.8s ease-in-out;
    margin-right: -32px !important;
  }
  .navbar .menu-items li {
    line-height: 66px;
  }

  .wrapper ul.r-1 {
    top: 60%;
    left: -2%;
  }
  .nav-img img {
    width: 35%;
    bottom: 115px;
    right: 400px;
  }
  .menu-btn {
    right: 18px;
  }
}

/* ------------------------------------------------------------------------------------------------------------
----------------------------------------------------   MOBILE -------------------------------------------------
-----------------------------------------------------------------------------------------------------------  */

@media (max-width: 767px) {
  .ewallet-img-container {
    height: 500px;
  }

  .cont-brand {
    text-align: left;
    align-self: center;
  }
  /*   .lgbtq{
    animation: slide 100s linear infinite;
}
@keyframes slide {
    0% {
      background-position-x: 0%;
    }
    50% {
        background-position: 2000%;
    }
    100% {
        background-position-x: 0%;
    }
  } */

  /* --- Header navbar --- */
  .navbar {
    padding: 0 !important;
  }
  .navbar-brand {
    margin-top: -5px !important;
    margin-left: 0.7rem;
  }
  /*-------------------------------------
  ----------- Navbar Menu ------------*/

  .nav-container {
    padding: 0px !important;
    position: relative;
    height: 70px !important;
  }
  .logo {
    position: absolute;
    top: 18px;
    left: 8px;
  }
  .nav-container .checkbox {
    top: 14px;
    right: 20px;
  }
  .nav-container .hamburger-lines {
    height: 18px;
    width: 25px;
    top: 22px;
    right: 20px;
  }
  .navbar .menu-items {
    background: #000;
    height: 100vh;
    width: 100%;
    transform: translate(150%);
    transition: transform 0.9s ease-in-out;
    padding: 50px 70px 50px 50px;
    margin-right: 0px !important;
  }
  .cont-detil {
    padding: 40px 0 0;
  }
  .navbar .menu-items li {
    line-height: 58px;
  }

  .contain {
    position: relative;
    width: 100%;
  }
  .contain .section {
    position: relative;
    width: 100%;
    height: 500px;
  }
  .contain .section:nth-of-type(1) {
    background: #95a5a6;
  }
  .contain .section:nth-of-type(2) {
    background: #7f8c8d;
  }

  /**********ul.toogle*********/
  .contain ul.toggle {
    position: fixed;
    top: 2.5%;
    right: 8%;
    width: 42px;
    height: 26px;
    cursor: pointer;
    z-index: 99999;
  }
  .contain ul.toggle li {
    position: absolute;
    left: 75%;
    transform: translateY(-50%);
    height: 2px;
    width: 60%;
    list-style: none;
    background: #000;
    transition: 0.9s;
  }
  .contain ul.toggle li:nth-of-type(1) {
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    background: #fff !important;
  }
  .contain ul.toggle li:nth-of-type(2) {
    left: -100%;
    opacity: 0;
  }
  .contain ul.toggle li:nth-of-type(3) {
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
    background: #fff !important;
  }
  .contain ul.toggle.active li:nth-of-type(1) {
    top: 20%;
    transform: translateY(0) rotate(180deg);
    background: #000 !important;
  }
  .contain ul.toggle.active li:nth-of-type(2) {
    top: 55%;
    left: 38px;
    width: 18px;
    opacity: 10;
  }
  .contain ul.toggle.active li:nth-of-type(3) {
    top: 81%;
    transform: translateY(0) rotate(180deg);
    background: #000 !important;
  }

  /******Sidebar******/
  .contain .sidebar {
    position: fixed;
    top: 0;
    right: -100%;
    background: #000;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    padding: 50px 40px;
    transition: 0.9s;
    z-index: 4;
  }
  .contain .sidebar.active {
    right: 0;
    z-index: 9999;
  }
  .contain .sidebar ul.menu {
    position: relative;
    width: 100%;
    margin-top: 20px;
  }
  .contain .sidebar ul.menu li {
    list-style: none;
  }
  .contain .sidebar ul.menu li a {
    display: inline-block;
    position: relative;
    text-decoration: none;
    color: #fff;
    width: 100%;
    padding: 12px 0;
    box-sizing: border-box;
    font-size: 32px;
    letter-spacing: 1.2px;
    line-height: 48px;
  }

  /* --- Home banner --- */
  .banner__content {
    margin-top: 140px;
    margin-bottom: 40px;
    padding: 0 8px;
  }
  .banner__text {
    padding: 0;
  }
  .banner__text h1 {
    color: #000000;
    font-size: 32px;
    font-weight: 300;
    line-height: 1.4em;
    letter-spacing: -0.02rem;
    padding: 0;
  }
  .banner__btn a {
    color: #000000;
    font-size: 18px;
    font-weight: 500;
    text-decoration: underline !important;
    line-height: 32px;
    letter-spacing: 0.5px;
  }
  .banner__btn a:hover {
    color: #000;
  }

  /* --- overview video ---*/
  .overview {
    position: relative;
    object-fit: cover;
    visibility: inherit;
    opacity: 1;
    transform: translate(0px, 0px);
    background-color: transparent;
    background-image: linear-gradient(180deg, #ffffff 8%, #000000 8%);
    /* z-index: -999; */
  }
  .overview video {
    max-width: 100%;
    /* width: 1120px; */
    /* height: 240px; */
    margin: 0;
    line-height: 1;
    border: none;
    padding: 0 10px;
  }
  .overview-cont h2 {
    color: #ffffff;
    font-family: "Source Sans Pro", Sans-serif;
    font-size: 24px;
    font-weight: 300;
    line-height: 40px;
    padding: 30px 15px 30px 10px;
  }

  /* ---- Footer ----- */
  .foot-form {
    margin-top: 110px;
    margin-bottom: 0px;
    padding: 10px !important;
  }
  .foot-form-cont h2 {
    color: #2d2d2d;
    font-size: 40px;
    font-weight: 300;
    line-height: 48px;
    letter-spacing: -0.02rem;
    padding-left: 12px;
  }
  .form-group {
    padding: 0px 20px 0px 0px;
  }
  .errors {
    margin-left: 10px;
  }
  .form-control {
    margin-left: 10px;
  }
  .foot-address {
    padding: 32px 30px 0px 30px !important;
  }
  .foot-addr:nth-child(1) h3 {
    color: #2d2d2d;
    font-size: 18px;
    font-weight: 300;
    line-height: 40px;
    margin-bottom: 0;
    padding-right: 60px;
  }
  .foot-li {
    padding: 15px;
    flex-wrap: nowrap !important;
    width: 50%;
  }
  .foot-li h2 {
    font-weight: 400;
  }
  .footer-addr {
    padding: 40px 15px;
  }

  /* -- Project works -- */
  .proj-works {
    margin-top: 100px;
    margin-bottom: 0px;
    padding: 0;
  }
  .project_works {
    padding: 10px;
  }
  .project_works video {
    width: 100%;
  }
  .project_works h3 {
    color: #0c0d15;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 1;
    letter-spacing: -0.02rem;
    padding-top: 10px;
  }
  .project_works h4 {
    color: #383838;
    font-size: 25px;
    font-weight: 300;
    line-height: 30px;
    padding-top: 10px;
    padding-right: 20px;
  }
  .project_works a {
    text-decoration: none !important;
  }
  .proj-works-top {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  /* -- OurClients -- */
  .our-client {
    padding: 44px 20px 0px 20px;
    text-align: center;
  }
  .our-client h4 {
    color: #0c0d15;
    font-size: 32px;
    font-weight: 300;
    line-height: 48px;
  }
  .our-client p {
    color: #000000;
    font-size: 18px;
    font-weight: 300;
    line-height: 32px;
    padding-bottom: 30px;
  }
  .our-client img {
    width: 85%;
  }

  /* --- See Our thinking --- */

  /* ------------------------
            About Page 
    ------------------------ */

  .about-page {
    margin-top: 91px;
    margin-bottom: 20px;
  }
  .about-ban-cont {
    padding: 10px 30px 10px 30px !important;
  }
  .about-ban-cont h2 {
    color: #000000;
    font-size: 37px;
    font-weight: 300;
    line-height: 45px;
    letter-spacing: -0.02rem;
  }
  .about-ban-cont p {
    color: #2d2d2d;
    font-size: 17px;
    font-weight: 300;
    line-height: 30px;
    padding: 0;
    margin-bottom: 10px;
  }
  .about-ban-img img {
    width: 88%;
  }
  .about-bg-cont p {
    color: #ffffff;
    font-size: 24px;
    font-weight: 300;
    line-height: 40px;
    padding: 10px 40px 10px 40px;
  }
  .about-bg {
    position: relative;
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 50px 0px 50px 0px;
  }
  .aanim-cont h2 {
    color: #2d2d2d;
    font-family: "Muthulais", Sans-serif;
    font-size: 60px;
    font-weight: 400;
    text-align: center;
    letter-spacing: -0.02rem;
  }
  .aanim-cont p {
    color: #2d2d2d;
    font-size: 19px;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: -0.02rem;
    text-align: center;
    padding: 0 20px;
  }
  .aanim-creativ {
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 0 20px 0 20px !important;
  }
  .aanim-cre-resp h2 {
    color: #2d2d2d;
    font-family: "Muthulais", Sans-serif;
    font-size: 52px;
    font-weight: 300;
    letter-spacing: -0.02rem;
    text-align: center;
  }
  .aanim-cre-resp p {
    color: #2d2d2d;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: -0.02rem;
    text-align: center;
    padding: 0 15px;
  }

  /* ------------------------
            Work Page 
    ------------------------ */

  .work-ban-cont h2 {
    padding-left: 10px;
  }
  .work_pages h4 {
    padding: 0px 0px 50px 0px;
  }

  /* ------------------------
            Service Page 
    ------------------------ */

  .services {
    position: relative;
    margin-top: 80px;
    margin-bottom: 40px;
    padding: 0 10px;
  }
  .serv-bann-cont h2 {
    padding-right: 0px;
    padding-bottom: 20px;
  }
  .serv-bg {
    padding-top: 36px;
    padding-bottom: 30px;
  }
  .serv-brand-overview h2 {
    text-align: left;
    padding-left: 10px;
  }
  .serv-brand-overview p {
    text-align: left;
    margin: 0px 20px 0px 0px;
    padding: 0 10px;
  }
  .serv-brand {
    margin-top: 40px !important;
    margin-bottom: 0px;
    padding: 10px !important;
  }
  .serv-brand-cont h4 {
    padding: 5% 0% 0% 0%;
  }
  .serv-brand-cont p {
    padding: 0% 0% 0% 0%;
  }
  .serv-capblt {
    margin-top: 32px;
    margin-bottom: 0px;
    padding: 10px;
  }
  .flex-dire {
    flex-direction: column-reverse;
  }

  /* --------- my persona ----------*/

  .my-perso-head {
    margin-top: 130px;
  }
  .my-perso-head h3 {
    font-size: 32px;
    font-weight: 300;
    line-height: 40px;
    letter-spacing: -0.3px;
    padding: 0 10px;
  }
  .my-perso-img {
    padding: 10px 25px 0 15px;
  }
  .my-perso-obj {
    margin: 55px 10px 20px 15px;
  }
  .my-perso-res {
    margin: 40px 15px 20px 15px;
  }
  .my-perso-res-img {
    padding: 0 10px 10px 10px;
  }
  .my-perso-res-cont p {
    padding-right: 32px;
  }
  .my-perso-ul {
    display: block;
    margin-top: 40px;
  }
  .my-perso-li {
    padding-left: 20px !important;
  }
  .my-perso-li- {
    margin-top: -16px !important;
  }
  .my-perso-li li {
    color: #000;
    font-size: 16px;
    font-weight: 300;
    line-height: 32px;
    margin-right: 10px;
  }
  .my-perso-li li::marker {
    font-size: 20px;
  }
  .my-perso-wf {
    margin: 100px 15px 40px 15px;
  }
  .my-perso-wf-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 20px;
  }
  .my-perso-img-wf {
    margin-top: 20px;
    margin-left: 0 !important;
  }
  .my-perso-ill {
    margin: 100px 0 40px 0;
    padding: 0 12px;
  }
  .my-perso-ill-cont {
    padding-bottom: 20px !important;
  }
  .my-perso-ill-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 40px;
  }
  .my-perso-ill-cont p {
    color: #000;
    font-size: 16px;
    font-weight: 200;
    line-height: 32px;
    padding-top: 20px;
    padding-right: 20px;
  }
  .my-perso-ill-video {
    max-height: 50vh !important;
    margin-bottom: 40px;
    overflow: hidden;
  }
  .img-ill {
    margin-top: 0;
    padding: 0 8px 0 8px;
  }
  .my-persona-img-ill-0 {
    background-color: #f0f0f0;
    margin-bottom: 135px;
  }
  .img-ill-0 {
    padding: 60px 10px 0px 10px;
  }
  .img-ill-01 {
    margin: 30px 0px -132px 0px;
  }

  /* ---------- Vinveli-Web page -------------- */

  .vinveli-head {
    margin-top: 120px;
  }
  .vinveli-head-cont {
    padding: 10px !important;
  }
  .vinveli-head-cont h2 {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02rem;
  }
  .vinveli-head-cont h3 {
    font-size: 28px;
    font-weight: 300;
    line-height: 40px;
    letter-spacing: -0.02rem;
  }
  .vinveli-img {
    margin-top: 30px;
  }

  .vinveli-obj {
    margin-top: 37px;
    margin-bottom: 20px;
  }
  .vinveli-obj-cont {
    padding-bottom: 30px;
  }
  .vinveli-obj-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 20px;
  }
  .vinveli-obj-cont p {
    color: #000000;
    font-size: 16px;
    font-weight: 200;
    line-height: 32px;
    padding-right: 10px;
  }

  .vinveli-res {
    margin: 20px 0 40px 0;
  }
  .vinveli-res-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 20px;
  }
  .vinveli-res-cont p {
    color: #000;
    font-size: 16px;
    font-weight: 200;
    /* letter-spacing: 1.5px; */
    line-height: 32px;
    padding-top: 10px;
    padding-right: 30px;
  }

  .vinveli-ul {
    display: flex;
    margin-top: 40px;
  }
  .vinveli-ul- {
    margin-top: -18px !important;
  }
  .vinveli-li {
    padding-left: 20px !important;
  }
  .vinveli-li li {
    color: #000;
    font-size: 16px;
    font-weight: 300;
    line-height: 36px;
  }
  .vinveli-li li::marker {
    font-size: 20px;
  }

  .vinveli-desin {
    margin-top: 80px;
    margin-bottom: 20px;
  }
  .vinveli-desin {
    padding: 0 18px;
  }
  .vinveli-desin-text h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 30px;
  }
  .vinveli-desin-ul h5 {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
  }
  .vinveli-desin-ul {
    padding: 12px !important;
  }
  .vinveli-desin-li {
    padding-left: 20px !important;
  }
  .vinveli-desin-li li {
    color: #000000;
    font-size: 16px;
    font-weight: 300;
    line-height: 34px;
  }
  .vinveli-desin-li li::marker {
    font-size: 20px !important;
  }

  .vinveli-wf {
    margin: 80px 0 40px 0;
  }
  .vinveli-wf-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 20px;
  }
  .vinveli-wf-img {
    margin-top: 20px;
    padding: 0 !important;
  }

  .vinveli-bg-img {
    background-image: url("https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/90ceaf7f-3v-bg.png");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: 80px;
    margin-bottom: 0px;
    position: relative;
  }
  .vinveli-cov-img {
    width: 100%;
  }
  .vinveli-bg-img0 {
    background-color: #07212b;
    margin-top: 20px;
    margin-bottom: 0px;
    position: relative;
  }
  .vinveli-cov-img0 {
    padding: 44px 10px 44px 10px !important;
  }
  .vinveli-cov-img1 {
    margin-top: 60px;
    margin-bottom: 0px;
  }
  .vinveli-bg-img2 {
    background-image: url("https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/90ceaf7f-3v-bg.png");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 30px;
    margin-bottom: 0px;
    position: relative;
  }
  .vinveli-cov-img2 {
    width: 60%;
  }

  /* ------------- Vinveli -------------*/

  .vinveli-bg {
    margin-top: -90px;
    min-height: 220px;
  }
  .vinveli-over {
    margin-top: 50px;
    margin-bottom: 32px;
    padding: 0 30px !important;
  }
  .vinv-over {
    padding: 10px !important;
  }
  .vinv-over h2 {
    font-size: 24px;
    font-weight: 300;
    line-height: 32px;
    text-align: left;
    margin-bottom: 0;
  }
  .vinv-over p {
    padding: 0;
  }
  .vinv-video video {
    width: 100%;
    padding: 10px 18px;
  }
  .vinv-image {
    padding: 20px 18px;
  }
  .vinv-cont {
    margin: 20px 0px;
    padding: 0 18px !important;
    text-align: left;
  }
  .vinv-cont h2 {
    font-size: 29px;
    line-height: 40px;
  }
  .vinv-cont p {
    padding: 0;
  }
  .vinv-note {
    padding: 0 8px;
  }
  .vinv-note-cont p {
    padding: 10px 2px 10px 10px;
  }
  .vinv-note-img {
    margin-top: 60px;
    padding: 0 !important;
  }
  .vinveli-bg1 {
    margin-top: 0px;
    min-height: 300px;
  }
  .vinv-pistol {
    width: 100%;
    position: relative;
  }
  .vinv-pistol img {
    width: 200%;
    max-width: 100%;
    margin: -200px 0px -100px 12px;
  }
  .vinv-pisto-cont {
    padding: 10px 30px 20px 30px !important;
  }
  .vinv-pisto-cont p {
    font-size: 18px;
    margin: 50px 0px 0px 0px;
  }
  .vinv-pistol-img {
    margin-top: 0px;
    padding: 20px !important;
  }
  .vinv-pisto-para {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px 20px !important;
  }
  .vinv-pisto-para p {
    text-align: left;
    padding: 0 10px;
  }
  .vinv-social {
    padding: 10px;
  }
  .vinv-social video {
    padding: 0px 18px 10px 18px;
  }
  .vinv-social img {
    margin-top: 0px;
    padding: 0px 18px !important;
  }

  /* ---------- Freshry -----------*/

  .freshey-overv {
    margin-top: 40px;
    padding: 10px !important;
  }
  .freshey-bann-img {
    margin-top: 32px;
    padding: 10px;
  }
  .freshey-img img {
    width: 55%;
    margin: 0px 0 40px 0;
    text-align: left !important;
  }
  .freshey-img:nth-child(3) img {
    width: 83%;
    margin: 20px 0;
  }
  .freshey-para p {
    color: #2d2d2d;
    font-size: 18px;
    font-weight: 300;
    line-height: 32px;
    padding: 0px 20px !important;
    text-align: left;
  }
  .freshey-para:nth-child(4) p {
    color: #2d2d2d;
    font-size: 18px;
    font-weight: 300;
    line-height: 32px;
    margin: 20px 0 30px 0;
    padding: 0px 20px !important;
    text-align: left;
  }
  .freshey-prod-img {
    padding: 10px !important;
    margin-bottom: 20px;
  }
  .freshey-prod-img img {
    margin-bottom: 8px;
  }

  /* -------- Intellect --------*/

  .intellect-over {
    margin: 30px 0 50px 0 !important;
    padding: 0px;
  }
  .intellect-over-cont p {
    margin: 0 0 20px 0;
  }
  .intellect-para p {
    text-align: left;
    padding: 0 15px;
  }
  .intellect-patt-bg {
    min-height: 130px;
  }

  /* --------- Bayleaf -----------*/
  .bayleaf-ban-img {
    margin-top: 15px;
    padding: 10px;
  }
  .bayleaf-over-cont p {
    color: #000000;
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    line-height: 32px;
    padding: 0 6px 0 6px;
  }
  .bayleaf-over-img {
    padding: 10px;
  }
  .bayleaf-social img {
    width: 100%;
    padding: 18px;
  }
  .bayleaf-social:nth-child(2) img {
    margin: 0;
  }
  .bayleaf-social:nth-child(4) img {
    margin: 0;
  }
  .bayleaf-cont {
    padding: 10px 10px 150px 10px;
  }
  .bayleaf-cont p {
    font-size: 18px;
    line-height: 36px;
    padding: 20px 10px 0 8px;
  }
  .bayleaf-cont ul {
    margin: 0 !important;
    padding-left: 28px !important;
  }
  .bayleaf-cont li {
    text-align: left;
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    line-height: 40px;
  }

  /* ------ Writers Cafe --------*/
  .writercafe-bann img {
    margin-top: -20px !important;
    padding: 10px !important;
  }
  .writer-cafe {
    margin-top: -20px !important;
    margin-bottom: 40px;
    padding: 10px;
  }
  .writercafe-cont {
    padding: 20px !important;
  }
  .writercafe-cont h2 {
    padding-top: 20px;
    padding-bottom: 0px;
  }
  .writercafe-cont p {
    font-size: 18px;
    line-height: 32px;
    padding: 0 10px;
  }
  .writercafe-bg img {
    margin-top: -60px;
  }
  .writer-cafe-img {
    height: 387px !important;
  }
  .para-cont {
    padding: 0 10px;
  }
  .para-cont p {
    padding: 50px 0px 30px 0px;
  }

  /* ----------- Asian Paints ---------*/
  .asian-slide {
    margin-top: 20px;
  }
  .asian-slide-marg {
    margin-left: 0px !important;
    height: 280px;
  }
  .asianpaint-para p {
    padding-top: 40px;
    padding-right: 30px;
    padding-left: 18px !important;
  }
  .asian-paint-para {
    padding: 40px 40px 0 30px !important;
  }
  .asian-paint-para ul {
    padding-top: 0px;
  }
  .asian-wdth:nth-child(1) img {
    max-width: 100% !important;
  }
  .asian-wdth:nth-child(2) img {
    max-width: 100% !important;
    margin-left: 0px;
  }
  .asian-slide-carousel0 {
    height: 320px;
  }
  .asian-slide-carousel {
    height: 500px;
  }
  .asian-des-txt h4 {
    text-align: center;
    padding: 10px;
    margin-top: 0px !important;
  }
  .asian-img-design {
    padding: 10px 20px !important;
    max-width: 100% !important;
  }
  .asian-img-design0 {
    padding: 10px 20px !important;
    max-width: 100% !important;
    margin-left: 0px;
  }
  .asi-padd {
    padding: 10px 20px !important;
  }

  /* ---------- L exotique ------------*/

  .lexotique-img {
    width: 56%;
    margin-top: 20px;
  }
  .lexotique-cont p {
    font-size: 18px;
    line-height: 32px;
    padding: 50px 12px 0 12px;
  }
  .lexotique-padd {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .lexotique-bg-para p {
    color: #ffffff;
    font-size: 20px;
    font-weight: 300;
    line-height: 32px;
    letter-spacing: -0.02rem;
    text-align: center;
    padding: 60px 10px;
  }

  /* -------____Responsive___---------*/
  .respo-cont h3 {
    font-size: 28px !important;
  }
  /*----------------------------------*/

  /* ----------- Mehta Jewellery ----------*/

  .mehta-jewell-cont {
    padding: 30px 20px !important;
  }
  .mehta-jewell-cont p {
    font-size: 18px;
    line-height: 32px;
    text-align: left;
    padding: 10px;
    margin-bottom: 0;
  }
  .mehta-bg {
    position: relative;
    margin-top: 0px;
    margin-bottom: 30px;
    padding: 50px 15px;
  }
  .mehta-bg-img {
    text-align: left;
  }
  .mehta-bg-cont img {
    width: 50%;
    margin-left: -40px;
    margin-top: 20px;
    padding-bottom: 10px;
  }
  .mehta-bg-cont p {
    font-size: 18px;
    font-weight: 300;
    line-height: 32px;
    letter-spacing: -0.02rem;
    padding-right: 20px;
  }

  /* --------- Curiohh ---------*/

  .curiohh-over {
    margin-top: 30px;
  }
  .curiohh-cont p {
    padding-right: 25px;
    padding-left: 25px;
    text-align: center;
  }
  .curiohh-para {
    margin-top: 44px;
    padding: 0 7px;
  }
  .curiohh-para p {
    font-size: 18px;
    font-weight: 300;
    line-height: 32px;
  }
  .align-text p {
    padding: 0px !important;
    text-align: left !important;
  }
  .curiohh-over .col-lg-6 {
    padding: 10px 22px !important;
  }
  .curiohh-img-width {
    width: 100%;
  }
  .curiohh-img0 {
    margin-top: 0px !important;
    margin-left: 0px !important;
    padding: 20px !important;
  }

  /* ---------- Waycool -----------*/

  .waycool-bann {
    margin-top: 136px;
    padding: 22px !important;
  }
  .waycool-bann-cont h3 {
    font-size: 28px;
    line-height: 40px;
  }
  .waycool-bann-img {
    width: 92.3%;
    margin-top: 0px;
    margin-left: 0px;
  }
  .waycool-over {
    margin: 40px 0;
  }
  .waycool-over-cont {
    padding: 12px !important;
  }
  .waycool-over-cont h3 {
    font-size: 24px;
    line-height: 32px;
  }
  .waycool-bg-cont p {
    font-size: 20px;
    line-height: 30px;
    padding: 0 20px;
  }
  .waycool-brand {
    margin-top: 20px !important;
    padding: 12px !important;
  }
  .waycool-brand p {
    padding-right: 10px;
  }
  .waycool-brand li {
    padding-right: 40px;
  }
  .waycool-bg-strat {
    padding: 30px 12px;
  }
  .waycool-strat-cont p {
    padding: 0 0px 40px 0;
  }
  .build {
    padding: 10px 22px !important;
  }
  .waycool-bg-flo {
    margin-top: 45px;
  }
  .waycool-flo-cont img {
    padding: 20px 0;
  }
  .waycool-flo-cont p {
    text-align: center;
    padding-right: 40px;
    padding-left: 40px;
  }

  /* ------------ Abbys Page -----------*/

  .abbys-cont {
    padding: 10px !important;
  }
  .wdth {
    width: 100% !important;
  }
  .wdth0 {
    width: 100% !important;
  }

  /* ------------ Vinita hospital Page -----------*/
  .vinita-alpha {
    margin-top: 0px;
    padding: 10px !important;
  }
  .vinita-cont p {
    font-size: 18px;
    line-height: 32px;
    padding: 0 20px;
  }
  .vinita-resp {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .vinita-resp p {
    text-align: left;
    padding: 0 5px;
  }
  .vinita-column {
    flex-direction: column-reverse;
  }
  .vinita-wdt {
    width: 75%;
  }
  .vinita-bg-cont {
    padding: 150px 30px 0px 30px !important;
  }
  .vinita-bg-cont p {
    font-size: 18px;
    line-height: 32px;
    text-align: left;
  }
  .vinita-img {
    text-align: left;
  }
  .vinita-img-bg {
    padding-bottom: 50px !important;
  }
  .vinita-img img {
    margin-top: 10px;
  }
  .vinita-bg0-img img {
    margin-top: -150px !important;
    padding: 10px !important;
  }
  .vinita-img0-para h4 {
    padding: 0 8px 0 8px;
  }
  .vinita-padd {
    padding-bottom: 30px !important;
  }
  .vinita-padd0 {
    padding: 30px 0 80px 0 !important;
  }
  .vinita-img0-para p {
    padding: 0 35px 0 8px;
  }
  .vinita-tit h4 {
    padding-bottom: 0px;
  }
  .vinita-chrt {
    margin-top: 20px !important;
  }
  .vinita-chrt-img {
    text-align: center;
  }

  /* ------------ Desi Di page -----------*/

  .desi-cont p {
    text-align: left;
    padding: 0px 12px 0px 18px;
  }
  .desi-wdt {
    width: 50% !important;
  }
  .desidi-img {
    width: 55%;
    margin: 10px 0 20px 0;
  }
  .desi-img:nth-child(3) {
    margin-top: 0px !important;
  }
  .desi-di-img:nth-child(2) img {
    margin-top: 0px !important;
  }
  .desi-img {
    padding: 10px 20px !important;
  }
  .desi-di-lists ul {
    display: block;
  }

  /* ------------- Kitchen Ji Page -----------*/
  .kitchen-cont {
    margin-top: 32px !important;
    padding: 10px 30px !important;
  }
  .kitchen-cont h4 {
    font-size: 32px;
    text-align: left;
  }
  .kitchen-cont p {
    text-align: left;
    padding-right: 20px;
  }
  .kitchen-ji-cont {
    margin-top: 0px !important;
  }
  .kitchen-ji-cont p {
    padding: 0 38px;
  }
  .kitchen-ji-cont img {
    width: 65%;
    margin-top: 30px;
    padding: 10px;
  }

  /* ----------- Curiohh Travel Page ----------*/

  .curiohh-trvl {
    flex-direction: column-reverse;
    padding: 0px 20px;
  }

  .curiohh-trvl2 {
    margin-top: 0px !important;
    padding: 0px 20px;
  }

  .curiohh-trvl3 {
    margin-top: 40px !important;
    padding: 0px 20px;
  }

  .curiohh-trvl-cont p {
    padding: 0px !important;
    font-size: 21px;
    line-height: 35px;
  }

  .curiohh-trvl-bg {
    margin-top: 30px !important;
  }

  .curiohh-cont h4 {
    font-size: 24px;
  }

  /* ----------- Amelies Page -----------*/
  .amelie-resp p {
    text-align: left !important;
  }
  .amelie-resp {
    justify-content: left !important;
  }
  .amelie-resp img {
    text-align: left !important;
    width: 40% !important;
  }
}

@media (max-width: 1024px) {
  /* ----------- Curiohh Travel Page ----------*/

  .curiohh-trvl {
    padding: 0px 20px;
  }

  .curiohh-trvl2 {
    margin-top: 0px !important;
    padding: 0px 20px;
  }

  .curiohh-trvl3 {
    margin-top: 40px !important;
    padding: 0px 20px;
  }

  .curiohh-trvl-cont p {
    padding: 0px !important;
    font-size: 21px;
    line-height: 35px;
  }

  .curiohh-trvl-bg {
    margin-top: 30px !important;
  }

  .curiohh-cont h4 {
    font-size: 24px;
  }
}

/* --- Airportzo --- */

@media (min-width: 1024px) {
  .airportzo-bg {
    background-color: #f8f8f8;
    margin-top: 80px !important;
    padding: 42px 0px 42px 0px;
  }
  .airportzo-img {
    padding: 0 10px 0 10px;
  }
  .airportzo-img-bg {
    background-color: transparent;
    background-image: linear-gradient(180deg, #ffffff 70%, #dbf4f9 70%);
    position: relative;
    margin-top: 80px !important;
    padding: 10px 10px 80px 10px !important;
    text-align: center;
  }
  .airportzo-abo-img {
    width: 80%;
  }
  .airportzo-img-bg0 {
    background-color: #f4f9fa;
    position: relative;
    margin-top: 80px !important;
    padding: 84px 10px 84px 10px !important;
    text-align: center;
  }
  .airportzo-img-bg1 {
    position: relative;
    margin-top: 30px !important;
    padding: 10px !important;
    text-align: center;
  }
}

@media (max-width: 1024px) {
  .airportzo-bg {
    background-color: #f8f8f8;
    margin-top: 40px !important;
    padding: 42px 0px 42px 0px;
  }
  .airportzo-img {
    padding: 0 10px 0 10px;
  }
  .airportzo-img-bg {
    background-color: transparent;
    background-image: linear-gradient(180deg, #ffffff 70%, #dbf4f9 70%);
    position: relative;
    margin: 40px 0 0 0 !important;
    padding: 0px 0px 80px 0px !important;
    text-align: center;
  }
  .airportzo-abo-img {
    width: 80%;
  }
  .airportzo-img-bg0 {
    background-color: #f4f9fa;
    position: relative;
    margin-top: 50px !important;
    padding: 44px 10px !important;
    text-align: center;
  }
  .airportzo-img-bg1 {
    position: relative;
    margin-top: 30px !important;
    padding: 10px !important;
    text-align: center;
  }
  .air-portzo-img {
    padding: 0 22px !important;
  }
}

/* --------- home page css ----------*/
/* --- home banner ---*/

@media (min-width: 1024px) {
  .banner {
    width: 100%;
    position: relative;
  }
  .banner__content {
    display: flex;
    align-items: center;
    margin-top: 205px;
    margin-bottom: 80px;
  }
  .banner__text h1 {
    color: #000000;
    font-size: 48px;
    font-weight: 300;
    line-height: 64px;
    letter-spacing: -0.02rem;
    padding-right: 30px;
  }
  .banner__btn a {
    color: #000000;
    font-size: 18px;
    font-weight: 500;
    text-decoration: underline !important;
    line-height: 32px;
    letter-spacing: 0.5px;
  }
  .banner__btn a:hover {
    color: #000;
  }
}

/* --- overview video ---*/

@media (min-width: 1024px) {
  .overview {
    position: relative;
    object-fit: cover;
    visibility: inherit;
    opacity: 1;
    transform: translate(0px, 0px);
    background-color: transparent;
    background-image: linear-gradient(180deg, #ffffff 14%, #000000 14%);
    /* z-index: -999; */
    padding-bottom: 75px;
  }
  .overview video {
    max-width: 100%;
    /* width: 1120px; */
    height: 570px;
    margin: 0;
    line-height: 1;
    border: none;
  }
  .overview-cont h2 {
    color: #ffffff;
    font-family: "Source Sans Pro", Sans-serif;
    font-size: 24px;
    font-weight: 300;
    line-height: 40px;
  }
}

/* --- --- */

@media (min-width: 1024px) {
  .contain {
    position: relative;
    width: 100%;
  }
  .contain .section {
    position: relative;
    width: 100%;
    height: 500px;
  }
  .contain .section:nth-of-type(1) {
    background: #95a5a6;
  }
  .contain .section:nth-of-type(2) {
    background: #7f8c8d;
  }

  /* *********ul.toogle******** */
  .contain ul.toggle {
    position: fixed;
    top: 2%;
    right: 14%;
    width: 32px;
    height: 27px;
    cursor: pointer;
    z-index: 99999;
  }
  .contain ul.toggle li {
    position: absolute;
    left: 75%;
    transform: translateY(-50%);
    height: 2px;
    width: 76%;
    list-style: none;
    background: #000;
    transition: 0.9s;
  }
  .contain ul.toggle li:nth-of-type(1) {
    left: 20%;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    background: #fff !important;
  }
  .contain ul.toggle li:nth-of-type(2) {
    left: -100%;
    opacity: 0;
  }
  .contain ul.toggle li:nth-of-type(3) {
    left: 20%;
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
    background: #fff !important;
  }
  .contain ul.toggle.active li:nth-of-type(1) {
    margin-left: -26px;
    left: 100%;
    top: 20%;
    transform: translateY(0) rotate(180deg);
    background: #000 !important;
  }
  .contain ul.toggle.active li:nth-of-type(2) {
    top: 53%;
    left: 12px;
    width: 18px;
    opacity: 10;
  }
  .contain ul.toggle.active li:nth-of-type(3) {
    margin-left: -26px;
    left: 100%;
    top: 80%;
    transform: translateY(0) rotate(180deg);
    background: #000 !important;
  }

  /******Sidebar******/
  .contain .sidebar {
    position: fixed;
    top: 0;
    right: -35%;
    background: #000;
    width: 35%;
    height: 100vh;
    box-sizing: border-box;
    padding: 50px 40px;
    transition: 0.9s;
    z-index: 4;
  }
  .contain .sidebar.active {
    right: 0;
    z-index: 9999;
  }
  .contain .sidebar ul.menu {
    position: relative;
    width: 100%;
    margin-top: 20px;
  }
  .contain .sidebar ul.menu li {
    list-style: none;
  }
  .contain .sidebar ul.menu li a {
    display: inline-block;
    position: relative;
    text-decoration: none;
    color: #fff;
    width: 100%;
    padding: 10px 0;
    box-sizing: border-box;
    font-size: 42px;
    font-weight: 300;
    text-transform: lowercase;
    line-height: 66px;
    letter-spacing: 1.2px;
  }
}

/* --------- my persona ----------*/

@media (min-width: 1024px) {
  .my-perso-head {
    margin-top: 146px;
    padding: 0 20px;
  }
  .my-perso-head h2 {
    color: #2d2d2d;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.02rem;
    padding: 0 10px;
  }
  .my-perso-head h3 {
    color: #2d2d2d;
    font-size: 40px;
    font-weight: 300;
    line-height: 48px;
    letter-spacing: -0.02rem;
    padding: 0 10px;
  }
  .my-perso-img {
    margin-left: -20px;
    padding-top: 44px;
  }
}

@media (min-width: 1024px) {
  .my-perso-obj {
    margin: 70px 0 40px 0;
  }
  .my-perso-obj-cont {
    padding: 0 10px !important;
  }
  .my-perso-obj-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 20px;
  }
  .my-perso-obj-cont p {
    color: #000;
    font-size: 18px;
    font-weight: 300;
    /* letter-spacing: 1.5px; */
    line-height: 32px;
    padding-top: 10px;
    padding-right: 170px;
  }
}

@media (min-width: 1024px) {
  .my-perso-res {
    margin: 70px 0 40px 0;
  }
  .my-perso-res-cont {
    padding: 0 10px !important;
  }
  .my-perso-res-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 20px;
  }
  .my-perso-res-cont p {
    color: #000;
    font-size: 18px;
    font-weight: 300;
    /* letter-spacing: 1.5px; */
    line-height: 32px;
    padding-top: 10px;
    padding-right: 60px;
  }
}

@media (min-width: 1024px) {
  .my-perso-ul {
    display: flex;
    margin-top: 40px;
  }
  .my-perso-li {
    padding-left: 30px !important;
  }
  .my-perso-li li {
    color: #000;
    font-size: 18px;
    font-weight: 300;
    line-height: 32px;
    margin-right: 180px;
  }
  li::marker {
    font-size: 20px;
  }
}

@media (min-width: 1024px) {
  .my-perso-wf {
    margin: 80px 0 40px 0;
  }
  .my-perso-wf-cont {
    padding: 10px !important;
  }
  .my-perso-wf-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 40px;
  }
  .my-perso-img-wf {
    margin-top: 100px;
    margin-left: 20px !important;
    padding: 10px;
  }
}

@media (min-width: 1024px) {
  .my-perso-ill {
    margin: 100px 0 30px 0;
  }
  .my-perso-ill-b {
    padding-bottom: 40px !important;
  }
  .my-perso-ill-cont {
    padding: 0 10px 20px 10px !important;
  }
  .my-perso-ill-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 40px;
  }
  .my-perso-ill-cont p {
    color: #000;
    font-size: 18px;
    font-weight: 300;
    line-height: 32px;
    padding-top: 20px;
  }
  .my-perso-ill-video {
    max-height: 50vh !important;
    margin-bottom: 40px;
    overflow: hidden;
  }
  .img-ill {
    margin-top: 60px;
    margin-left: 12px;
  }
  .my-persona-img-ill-0 {
    background-color: #f0f0f0;
    width: 1135px !important;
    margin-left: 12px;
    margin-bottom: 380px;
  }
  .img-ill-0 {
    padding: 100px 50px 0px 50px;
  }
  .img-ill-01 {
    width: 100%;
    margin: 100px 0px -380px 0px;
  }
}

@media (min-width: 1024px) {
  .vinveli-head {
    margin-top: 130px;
  }
  .vinveli-head-cont {
    padding: 10px !important;
  }
  .vinveli-head-cont h2 {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02rem;
  }
  .vinveli-head-cont h3 {
    font-size: 40px;
    font-weight: 300;
    line-height: 40px;
    letter-spacing: -0.02rem;
  }
  .vinveli-img {
    margin-top: 24px;
  }

  .vinveli-obj {
    margin-top: 70px;
    margin-bottom: 40px;
    padding: 10px !important;
  }
  .vinveli-obj-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 20px;
  }
  .vinveli-obj-cont p {
    color: #000000;
    font-size: 16px;
    font-weight: 200;
    line-height: 32px;
    padding-top: 10px;
    padding-right: 175px;
    letter-spacing: 0.02em;
  }

  .vinveli-res {
    margin: 70px 0 40px 0;
  }
  .vinveli-res-cont {
    padding: 0 10px !important;
  }
  .vinveli-res-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 20px;
  }
  .vinveli-res-cont p {
    color: #000;
    font-size: 16px;
    font-weight: 200;
    line-height: 32px;
    padding-top: 10px;
    padding-right: 10px;
    letter-spacing: 0.02em;
  }

  .vinveli-ul {
    display: flex;
    margin-top: 40px;
    padding: 15px !important;
  }
  .vinveli-li {
    padding-left: 20px !important;
  }
  .vinveli-li li {
    color: #000;
    font-size: 16px;
    font-weight: 300;
    line-height: 36px;
  }
  .vinveli-li li::marker {
    font-size: 20px;
  }

  .vinveli-desin {
    margin-top: 80px;
    margin-bottom: 20px;
  }
  .vinveli-desin-text,
  .vinveli-desin-text-ul {
    padding: 0 12px !important;
  }
  .vinveli-desin-text h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 30px;
  }
  .vinveli-desin-ul h5 {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
  }
  .vinveli-desin-li {
    padding-left: 20px !important;
  }
  .vinveli-desin-li li {
    color: #000000;
    font-size: 16px;
    font-weight: 300;
    line-height: 36px;
  }
  .vinveli-desin-li li::marker {
    font-size: 20px !important;
  }

  .vinveli-wf {
    margin: 80px 0 40px 0;
  }
  .vinveli-wf-cont {
    padding: 0 10px !important;
  }
  .vinveli-wf-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 30px;
  }
  .vinveli-wf-img {
    margin-top: 40px;
    padding: 10px !important;
  }

  .vinveli-bg-img {
    background-image: url("https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/90ceaf7f-3v-bg.png");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: 80px;
    margin-bottom: 0px;
    position: relative;
  }
  .vinveli-cov-img {
    width: 69%;
  }
  .vinveli-bg-img0 {
    background-color: #07212b;
    margin-top: 80px;
    margin-bottom: 0px;
    position: relative;
  }
  .vinveli-cov-img0 {
    padding: 84px 10px 84px 10px !important;
  }
  .vinveli-cov-img1 {
    margin-top: 60px;
    margin-bottom: 0px;
  }
  .vinveli-bg-img2 {
    background-image: url("https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/90ceaf7f-3v-bg.png");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 80px;
    margin-bottom: 0px;
    position: relative;
  }
}

@media (max-width: 767px) {
  .opp {
    margin-bottom: 40px;
    padding: 10px 20px !important;
  }

  .opp img {
    width: 100%;
  }

  .opp-form label {
    margin-left: 10px !important;
  }

  .opp-form {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .airportzo-bg {
    background-color: #f8f8f8;
    margin-top: 80px !important;
    padding: 42px 0px 42px 0px;
  }
  .airportzo-img {
    padding: 10px;
  }
  .airportzo-img-bg {
    background-color: transparent;
    background-image: linear-gradient(180deg, #ffffff 70%, #dbf4f9 70%);
    position: relative;
    margin-top: 40px !important;
    padding: 0px 0px 40px 0px !important;
    text-align: center;
  }

  .sotia h2 {
    text-align: center;
    color: #0c0d15;
    font-size: 30px;
    font-weight: 300;
    line-height: 40px;
    letter-spacing: -0.02rem;
    margin-bottom: 40px;
  }
  .airportzo-abo-img {
    width: 80%;
  }
  .airportzo-img-bg0 {
    background-color: #f4f9fa;
    position: relative;
    margin-top: 40px !important;
    padding: 44px 10px !important;
    text-align: center;
  }
  .airportzo-img-bg1 {
    position: relative;
    margin-top: 30px !important;
    padding: 10px !important;
    text-align: center;
  }
}

/* --- Author café ---*/

@media (min-width: 1024px) {
  .author-head {
    margin-top: 130px;
  }
  .author-head-cont {
    padding: 10px !important;
  }
  .author-head-cont h2 {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02rem;
  }
  .author-head-cont h3 {
    font-size: 32px;
    font-weight: 300;
    line-height: 40px;
    letter-spacing: -0.02rem;
  }
  .author-img {
    margin-top: 24px;
  }
}

@media (max-width: 1024px) {
  .author-head {
    margin-top: 130px;
    padding: 0 20px;
  }
  .author-head-cont {
    padding: 10px !important;
  }
  .author-head-cont h2 {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02rem;
  }
  .author-head-cont h3 {
    font-size: 30px;
    font-weight: 300;
    line-height: 40px;
    letter-spacing: -0.02rem;
    padding-right: 20px;
  }
  .author-img {
    margin-top: 30px;
    padding: 0 18px !important;
  }
}

@media (max-width: 767px) {
  .author-head {
    margin-top: 120px;
  }
  .author-head-cont {
    padding: 10px !important;
  }
  .author-head-cont h2 {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02rem;
  }
  .author-head-cont h3 {
    font-size: 28px;
    font-weight: 300;
    line-height: 40px;
    letter-spacing: -0.02rem;
  }
  .author-img {
    margin-top: 30px;
  }
}

@media (min-width: 1024px) {
  .author-about {
    margin-top: 70px;
    margin-bottom: 40px;
    padding: 10px !important;
  }
  .author-about-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
  }
  .author-about-cont p {
    color: #000000;
    font-size: 16px;
    font-weight: 200;
    line-height: 32px;
    padding-right: 115px;
  }
}

@media (max-width: 1024px) {
  .author-about {
    margin-top: 37px;
    margin-bottom: 40px;
    padding: 0 30px !important;
  }
  .author-about-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
  }
  .author-about-cont p {
    color: #000000;
    font-size: 16px;
    font-weight: 200;
    line-height: 32px;
    padding-right: 40px;
  }
}

@media (max-width: 767px) {
  .author-about {
    margin-top: 37px;
    margin-bottom: 20px;
  }
  .author-about-cont {
    padding-bottom: 30px;
  }
  .author-about-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
  }
  .author-about-cont p {
    color: #000000;
    font-size: 16px;
    font-weight: 200;
    line-height: 32px;
    padding-right: 20px;
  }
}

@media (min-width: 1024px) {
  .author-res {
    margin: 70px 0 40px 0;
  }
  .author-res-cont {
    padding: 0 10px !important;
  }
  .author-res-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 20px;
  }
  .author-res-cont p {
    color: #000;
    font-size: 16px;
    font-weight: 200;
    /* letter-spacing: 1.5px; */
    line-height: 32px;
    padding-top: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 1024px) {
  .author-res {
    margin: 70px 0 10px 0;
    padding: 0 18px;
  }
  .author-res-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 20px;
  }
  .author-res-cont p {
    color: #000;
    font-size: 16px;
    font-weight: 200;
    /* letter-spacing: 1.5px; */
    line-height: 32px;
    padding-top: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 767px) {
  .author-res {
    margin: 20px 0 40px 0;
  }
  .author-res-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 20px;
  }
  .author-res-cont p {
    color: #000;
    font-size: 16px;
    font-weight: 200;
    /* letter-spacing: 1.5px; */
    line-height: 32px;
    padding-top: 10px;
    padding-right: 20px;
  }
}

@media (min-width: 1024px) {
  .author-ul {
    display: flex;
    margin-top: 40px;
    padding: 15px !important;
  }
  .author-li {
    padding-left: 20px !important;
  }
  .author-li li {
    color: #000;
    font-size: 16px;
    font-weight: 300;
    line-height: 36px;
  }
  .author-li li::marker {
    font-size: 20px;
  }
}

@media (max-width: 1024px) {
  .author-ul {
    display: flex;
    margin-top: 40px;
  }
  .author-li {
    padding-left: 20px !important;
  }
  .author-li li {
    color: #000;
    font-size: 16px;
    font-weight: 300;
    line-height: 36px;
  }
  .author-li li::marker {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .author-ul {
    display: flex;
    margin-top: 40px;
  }
  .author-ul- {
    margin-top: -18px !important;
  }
  .author-li {
    padding-left: 20px !important;
  }
  .author-li li {
    color: #000;
    font-size: 16px;
    font-weight: 300;
    line-height: 36px;
  }
  .author-li li::marker {
    font-size: 20px;
  }
}

@media (min-width: 1024px) {
  .author-desin {
    margin-top: 80px;
    margin-bottom: 20px;
  }
  .author-desin-text,
  .author-desin-text-ul {
    padding: 0 12px !important;
  }
  .author-desin-text h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 30px;
  }
  .author-desin-ul h5 {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
  }
  .author-desin-li {
    padding-left: 20px !important;
  }
  .author-desin-li li {
    color: #000000;
    font-size: 16px;
    font-weight: 300;
    line-height: 36px;
  }
  .author-desin-li li::marker {
    font-size: 20px !important;
  }
}

@media (max-width: 1024px) {
  .author-desin {
    margin-top: 80px;
    margin-bottom: 20px;
  }
  .author-desin {
    padding: 0 18px;
  }
  .author-desin-text h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 30px;
  }
  .author-desin-ul h5 {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
  }
  .author-desin-ul {
    padding: 0 6px 0 12px !important;
  }
  .author-desin-li {
    padding-left: 20px !important;
  }
  .author-desin-li li {
    color: #000000;
    font-size: 16px;
    font-weight: 200;
    line-height: 34px;
  }
  .author-desin-li li::marker {
    font-size: 20px !important;
  }
}

@media (max-width: 767px) {
  .author-desin {
    margin-top: 80px;
    margin-bottom: 20px;
  }
  .author-desin {
    padding: 0 18px;
  }
  .author-desin-text h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 30px;
  }
  .author-desin-ul h5 {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
  }
  .author-desin-ul {
    padding: 12px !important;
  }
  .author-desin-li {
    padding-left: 20px !important;
  }
  .author-desin-li li {
    color: #000000;
    font-size: 16px;
    font-weight: 300;
    line-height: 34px;
  }
  .author-desin-li li::marker {
    font-size: 20px !important;
  }
}

@media (min-width: 1024px) {
  .author-user {
    margin-top: 80px;
    margin-bottom: 20px;
  }
  .author-user-cont {
    padding: 0 12px !important;
  }
  .author-user-cont1 {
    padding: 50px 12px 0 12px !important;
  }
  .author-user-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 30px;
  }
  .author-user-data {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
  }
  .author-user-data h6 {
    color: #000000;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
  }
  .author-user-data p {
    color: #000000;
    font-size: 17px;
    font-weight: 200;
    line-height: 32px;
  }
  .author-user-data-txt {
    padding: 0 0 0 50px !important;
  }
  .author-user-data-txt h5 {
    color: #000000;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
  }
  .author-user-data-txt p {
    color: #000000;
    font-size: 16px;
    font-weight: 200;
    line-height: 32px;
  }
}

@media (max-width: 1024px) {
  .author-user {
    margin-top: 80px;
    margin-bottom: 20px;
  }
  .author-user {
    padding: 0 18px;
  }
  .author-user-cont {
    padding: 0 12px !important;
  }
  .author-user-cont1 {
    padding: 50px 12px 0 0 !important;
  }
  .author-user-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 30px;
  }
  .author-user-data {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
  }
  .author-user-data h6 {
    color: #000000;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
  }
  .author-user-data p {
    color: #000000;
    font-size: 17px;
    font-weight: 200;
    line-height: 32px;
  }
  .author-user-data-txt {
    padding: 0 0 0 40px !important;
  }
  .author-user-data-txt h5 {
    color: #000000;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
  }
  .author-user-data-txt p {
    color: #000000;
    font-size: 16px;
    font-weight: 200;
    line-height: 32px;
  }
}

@media (max-width: 767px) {
  .author-user {
    margin-top: 80px;
    margin-bottom: 20px;
  }
  .author-user {
    padding: 0 18px;
  }
  .author-user-cont {
    padding: 0 12px !important;
  }
  .author-user-cont1 {
    padding: 50px 12px 0 12px !important;
  }
  .author-user-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 30px;
  }
  .author-user-data {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    margin-left: -250px;
    margin-top: 10px !important;
    width: 50% !important;
  }
  .author-user-resp {
    flex-wrap: nowrap !important;
  }
  .author-user-resp div {
    padding: 0 0 20px 0 !important;
  }
  .author-user-img {
    width: 25% !important;
  }
  .author-user-data h6 {
    color: #000000;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
  }
  .author-user-data p {
    color: #000000;
    font-size: 17px;
    font-weight: 200;
    line-height: 32px;
  }
  .author-user-data-txt {
    padding: 0 0 0 0 !important;
  }
  .author-user-data-txt h5 {
    color: #000000;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
  }
  .author-user-data-txt p {
    color: #000000;
    font-size: 16px;
    font-weight: 200;
    line-height: 32px;
    padding-right: 40px;
  }
}

@media (min-width: 1024px) {
  .author-wf {
    margin-top: 90px;
  }
  .author-wf-cont {
    padding: 0 12px !important;
  }
  .author-wf-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 30px;
  }
  .author-wf-img {
    padding: 40px 0;
  }
}

@media (max-width: 1024px) {
  .author-wf {
    margin-top: 90px;
  }
  .author-wf-cont {
    padding: 0 30px !important;
  }
  .author-wf-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 30px;
  }
  .author-wf-img {
    padding: 30px 0 20px 0;
  }
}

@media (max-width: 767px) {
  .author-wf {
    margin-top: 80px;
  }
  .author-wf-cont {
    padding: 0 24px !important;
  }
  .author-wf-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 30px;
  }
  .author-wf-img {
    padding: 30px 0 0;
  }
}

@media (min-width: 1024px) {
  .author-dsys {
    margin-top: 90px;
  }
  .author-dsys-cont {
    padding: 0 12px !important;
  }
  .author-dsys-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 30px;
  }
  .author-dsys-img {
    padding: 0 50% 0 0 !important;
  }
  .author-dsys-img0 {
    padding: 0 35% 0 0 !important;
  }
  .author-dsys-img1 {
    padding: 0 40% 0 0 !important;
  }
  .author-dsys-text {
    padding: 30px 10px !important;
  }
  .author-dsys-text h6 {
    color: #576b81;
    font-family: "Poppins", Sans-serif;
    font-size: 24px;
    font-weight: 400;
    font-style: normal;
    line-height: 36px;
    padding-bottom: 10px;
  }
}

@media (max-width: 1024px) {
  .author-dsys {
    margin-top: 50px;
  }
  .author-dsys-cont {
    padding: 0 30px !important;
  }
  .author-dsys-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 30px;
  }
  .author-dsys-img {
    padding: 0 50% 0 0 !important;
  }
  .author-dsys-img0 {
    padding: 0 35% 0 0 !important;
  }
  .author-dsys-img1 {
    padding: 0 40% 0 0 !important;
  }
  .author-dsys-text {
    padding: 30px 10px !important;
  }
  .author-dsys-text h6 {
    color: #576b81;
    font-family: "Poppins", Sans-serif;
    font-size: 24px;
    font-weight: 400;
    font-style: normal;
    line-height: 36px;
    padding-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .author-dsys {
    margin-top: 50px;
  }
  .author-dsys-cont {
    padding: 0 30px !important;
  }
  .author-dsys-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 30px;
  }
  .author-dsys-img {
    padding: 40px 0;
  }
  .author-dsys-img {
    padding: 0 50% 20px 0 !important;
  }
  .author-dsys-img0 {
    padding: 0 15% 0 0 !important;
  }
  .author-dsys-img1 {
    padding: 0 15% 0 0 !important;
  }
  .author-dsys-text {
    padding: 30px 10px 0 !important;
  }
  .author-dsys-text h6 {
    color: #576b81;
    font-family: "Poppins", Sans-serif;
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    line-height: 36px;
    padding-bottom: 10px;
  }
}

@media (min-width: 1024px) {
  .author-cards {
    margin-top: 80px;
  }
  .author-cards-cont {
    padding: 0 12px !important;
  }
  .author-cards-cont h5 {
    color: #212121;
    font-size: 22px;
    font-weight: 200;
    line-height: 40px;
  }
}

@media (max-width: 1024px) {
  .author-cards {
    margin-top: 90px;
    padding: 0 18px !important;
  }
  .author-cards-cont h5 {
    color: #212121;
    font-size: 22px;
    font-weight: 200;
    line-height: 40px;
  }
}

@media (max-width: 767px) {
  .author-cards {
    margin-top: 90px;
  }
  .author-cards-cont h5 {
    color: #212121;
    font-size: 22px;
    font-weight: 200;
    line-height: 40px;
    text-align: center;
  }
}

@media (min-width: 1024px) {
  .author-act {
    margin-top: 60px;
    padding: 0 12px !important;
  }
  .author-act h5 {
    color: #000;
    font-size: 22px;
    font-weight: 200;
    line-height: 40px;
  }
  .author-act-img {
    padding-top: 40px;
  }
}

@media (max-width: 1024px) {
  .author-act {
    margin-top: 60px;
    padding: 0 30px !important;
  }
  .author-act h5 {
    color: #000;
    font-size: 22px;
    font-weight: 200;
    line-height: 40px;
  }
  .author-act-img {
    padding-top: 30px;
  }
}

@media (max-width: 767px) {
  .author-act {
    margin-top: 60px;
    padding: 0 !important;
  }
  .author-act h5 {
    color: #000;
    font-size: 22px;
    font-weight: 200;
    line-height: 40px;
    text-align: center;
  }
  .author-act-img {
    padding: 0 !important;
  }
}

@media (min-width: 1024px) {
  .author-ps {
    padding: 0 12px !important;
  }
}
@media (max-width: 1024px) {
  .author-ps {
    padding: 0 18px !important;
  }
}
@media (max-width: 767px) {
  .author-ps {
    padding: 0 18px !important;
  }
}

@media (min-width: 1024px) {
  .author-ps-ill {
    margin-top: 70px;
    padding: 0 12px !important;
  }
  .author-ps-ill h5 {
    color: #000;
    font-size: 22px;
    font-weight: 200;
    line-height: 40px;
  }
  .author-site-img {
    padding: 40px 12px !important;
  }
  .author-site-img0 {
    padding: 40px 12px 0 12px !important;
  }
}

@media (max-width: 1024px) {
  .author-ps-ill {
    margin-top: 70px;
    padding: 0 12px !important;
  }
  .author-ps-ill h5 {
    color: #000;
    font-size: 22px;
    font-weight: 200;
    line-height: 40px;
    padding-bottom: 20px;
  }
  .author-site-img {
    padding: 40px 0 0 0 !important;
  }
}

@media (max-width: 767px) {
  .author-ps-ill {
    margin-top: 70px;
    padding: 0 12px !important;
  }
  .author-ps-ill h5 {
    color: #000;
    font-size: 22px;
    font-weight: 200;
    line-height: 40px;
    text-align: center;
  }
  .author-site-img {
    padding: 40px 0 0 0 !important;
  }
}

/* --- bharathi homes ---*/

@media (min-width: 1024px) {
  .bharathi-head {
    margin-top: 130px;
  }
  .bharathi-head-cont {
    padding: 10px !important;
  }
  .bharathi-head-cont h2 {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02rem;
  }
  .bharathi-head-cont h3 {
    font-size: 32px;
    font-weight: 300;
    line-height: 40px;
    letter-spacing: -0.02rem;
  }
  .bharathi-img {
    margin-top: 24px;
  }
}

@media (max-width: 1024px) {
  .bharathi-head {
    margin-top: 130px;
    padding: 0 20px;
  }
  .bharathi-head-cont {
    padding: 10px !important;
  }
  .bharathi-head-cont h2 {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02rem;
  }
  .bharathi-head-cont h3 {
    font-size: 30px;
    font-weight: 300;
    line-height: 40px;
    letter-spacing: -0.02rem;
    padding-right: 0px;
  }
  .bharathi-img {
    margin-top: 30px;
    padding: 0 18px !important;
  }
}

@media (max-width: 767px) {
  .bharathi-head {
    margin-top: 120px;
  }
  .bharathi-head-cont {
    padding: 10px !important;
  }
  .bharathi-head-cont h2 {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02rem;
  }
  .bharathi-head-cont h3 {
    font-size: 28px;
    font-weight: 300;
    line-height: 40px;
    letter-spacing: -0.02rem;
  }
  .bharathi-img {
    margin-top: 30px;
  }
}

@media (min-width: 1024px) {
  .bharathi-about {
    margin-top: 37px;
    margin-bottom: 40px;
  }
  .bharathi-about-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
  }
  .bharathi-about-cont p {
    color: #000000;
    font-size: 16px;
    font-weight: 200;
    line-height: 32px;
    padding-right: 115px;
  }
}

@media (max-width: 1024px) {
  .bharathi-about {
    margin-top: 37px;
    margin-bottom: 40px;
    padding: 0 30px !important;
  }
  .bharathi-about-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
  }
  .bharathi-about-cont p {
    color: #000000;
    font-size: 16px;
    font-weight: 200;
    line-height: 32px;
    padding-right: 40px;
  }
}

@media (max-width: 767px) {
  .bharathi-about {
    margin-top: 37px;
    margin-bottom: 20px;
  }
  .bharathi-about-cont {
    padding-bottom: 30px;
  }
  .bharathi-about-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
  }
  .bharathi-about-cont p {
    color: #000000;
    font-size: 16px;
    font-weight: 200;
    line-height: 32px;
    padding-right: 20px;
  }
}

@media (min-width: 1024px) {
  .bharathi-res {
    margin: 70px 0 40px 0;
  }
  .bharathi-res-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 20px;
  }
  .bharathi-res-cont p {
    color: #000;
    font-size: 16px;
    font-weight: 200;
    /* letter-spacing: 1.5px; */
    line-height: 32px;
    padding-top: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 1024px) {
  .bharathi-res {
    margin: 70px 0 10px 0;
    padding: 0 18px;
  }
  .bharathi-res-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 20px;
  }
  .bharathi-res-cont p {
    color: #000;
    font-size: 16px;
    font-weight: 200;
    /* letter-spacing: 1.5px; */
    line-height: 32px;
    padding-top: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 767px) {
  .bharathi-res {
    margin: 20px 0 40px 0;
  }
  .bharathi-res-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 20px;
  }
  .bharathi-res-cont p {
    color: #000;
    font-size: 16px;
    font-weight: 200;
    /* letter-spacing: 1.5px; */
    line-height: 32px;
    padding-top: 10px;
    padding-right: 20px;
  }
}

@media (min-width: 1024px) {
  .bharathi-ul {
    display: flex;
    margin-top: 40px;
  }
  .bharathi-li {
    padding-left: 20px !important;
  }
  .bharathi-li li {
    color: #000;
    font-size: 16px;
    font-weight: 300;
    line-height: 36px;
  }
  .bharathi-li li::marker {
    font-size: 20px;
  }
}

@media (max-width: 1024px) {
  .bharathi-ul {
    display: flex;
    margin-top: 40px;
  }
  .bharathi-li {
    padding-left: 20px !important;
  }
  .bharathi-li li {
    color: #000;
    font-size: 16px;
    font-weight: 300;
    line-height: 36px;
  }
  .bharathi-li li::marker {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .bharathi-ul {
    display: flex;
    margin-top: 40px;
  }
  .bharathi-ul- {
    margin-top: -18px !important;
  }
  .bharathi-li {
    padding-left: 20px !important;
  }
  .bharathi-li li {
    color: #000;
    font-size: 16px;
    font-weight: 300;
    line-height: 36px;
  }
  .bharathi-li li::marker {
    font-size: 20px;
  }
}

@media (min-width: 1024px) {
  .bharathi-wf {
    margin: 80px 0 40px 0;
  }
  .bharathi-wf-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 40px;
  }
  .bharathi-wf-img {
    margin-top: 60px;
    padding: 0 92px 0 92px !important;
  }
}

@media (max-width: 1024px) {
  .bharathi-wf {
    margin: 80px 0 40px 0;
    padding: 0 18px !important;
  }
  .bharathi-wf-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 30px;
  }
  .bharathi-wf-img {
    margin-top: 60px;
    padding: 0 62px 0 62px !important;
  }
}

@media (max-width: 767px) {
  .bharathi-wf {
    margin: 80px 0 40px 0;
  }
  .bharathi-wf-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 30px;
  }
  .bharathi-wf-img {
    margin-top: 20px;
    padding: 0 !important;
  }
}

@media (min-width: 1024px) {
  .bharathi-ill {
    margin: 100px 0 40px 0;
  }
  .bharathi-ill-cont {
    padding-bottom: 20px !important;
  }
  .bharathi-ill-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 40px;
  }
  .bharathi-ill-cont p {
    color: #000;
    font-size: 16px;
    font-weight: 200;
    line-height: 32px;
    padding-top: 20px;
  }
  .bharathi-ill-video {
    max-height: 50vh !important;
    margin-bottom: 40px;
    overflow: hidden;
  }
  .bharathi-img-ill {
    margin-top: 60px;
    padding: 10px !important;
  }
  .bharathi-img-ill0 {
    padding: 52px 71px 0px 119px !important;
  }
  .bharathi-img-ill1 {
    padding: 52px 49px 0px 49px !important;
  }
  .bharathi-img-ill2 {
    padding: 52px 99px 42px 99px !important;
  }
}

@media (max-width: 1024px) {
  .bharathi-ill {
    margin: 100px 0 40px 0;
    padding: 0 18px !important;
  }
  .bharathi-ill-cont {
    padding-bottom: 20px !important;
  }
  .bharathi-ill-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 30px;
  }
  .bharathi-ill-cont p {
    color: #000;
    font-size: 16px;
    font-weight: 200;
    line-height: 32px;
    padding-top: 20px;
  }
  .bharathi-ill-video {
    max-height: 50vh !important;
    margin-bottom: 40px;
    overflow: hidden;
  }
  .bharathi-img-ill {
    margin-top: 20px;
  }
  .bharathi-img-ill0 {
    padding: 52px 61px 0px 100px !important;
  }
  .bharathi-img-ill1 {
    padding: 52px 49px 0px 49px !important;
  }
  .bharathi-img-ill2 {
    padding: 52px 99px 30px 99px !important;
  }
}

@media (max-width: 767px) {
  .bharathi-ill {
    margin: 100px 0 40px 0;
  }
  .bharathi-ill-cont {
    padding-bottom: 20px !important;
  }
  .bharathi-ill-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 30px;
  }
  .bharathi-ill-cont p {
    color: #000;
    font-size: 16px;
    font-weight: 200;
    line-height: 32px;
    padding-top: 20px;
  }
  .bharathi-ill-video {
    max-height: 50vh !important;
    margin-bottom: 40px;
    overflow: hidden;
  }
  .bharathi-img-ill {
    margin-top: 60px;
  }
  .bharathi-img-ill0 {
    padding: 52px 0 0 0 !important;
  }
  .bharathi-img-ill1 {
    padding: 52px 0 0 0 !important;
  }
  .bharathi-img-ill2 {
    padding: 52px 0 30px 0 !important;
  }
}

/* --- E-wallet --- */

@media (min-width: 1024px) {
  .e-wallet-img {
    padding: 10px 30px 80px 0 !important;
  }
  .e-wallet-img0 {
    padding: 10px 30px 80px 0 !important;
  }
  .e-wallet-wf-img {
    margin-top: 30px !important;
  }
  .e-wallet-wf-img1 {
    padding: 10px !important;
  }
  .e-wallet-wf-img2 {
    margin-top: 50px !important;
    padding: 10px !important;
  }
  .e-wallet-wf-img3 {
    margin-top: 0 !important;
    padding: 10px !important;
  }
  .e-wallet-wf-img4 {
    margin-top: 50px !important;
    padding: 10px !important;
  }
  .e-wallet-persol {
    position: relative;
    margin-top: 100px;
    padding: 10px !important;
  }
  .e-wallet-persol-cont {
    background-color: #364556;
    border-radius: 20px 20px 20px 20px;
    min-height: 628px;
    margin: 10px;
  }
  .e-wallet-persol-cont h5 {
    color: #ffffff;
    font-size: 22px;
    font-weight: 300;
    line-height: 40px;
    text-align: center;
    padding-top: 42px;
  }
  .e-wallet-persol-img {
    margin-top: -550px !important;
    padding: 0 !important;
  }
  .e-wallet-persol-img1 {
    padding: 0 0 0 50px !important;
  }
  .e-wallet-persol-img2 {
    padding: 0 50px 0 0 !important;
  }
  .e-wallet-track {
    margin-top: 120px;
  }
  .e-wallet-track-bg {
    background-color: #364556;
    border-radius: 20px 20px 20px 20px;
    margin: 10px;
  }
  .e-wallet-track-cont {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .e-wallet-track-cont h5 {
    color: #ffffff;
    font-size: 22px;
    font-weight: 500;
    line-height: 40px;
  }
  .e-wallet-track-cont p {
    color: #ffffff;
    font-size: 16px;
    font-weight: 300;
    line-height: 32px;
  }
  .e-wallet-track-img {
    margin-top: -180px;
  }
}

@media (max-width: 1024px) {
  .e-wallet-img {
    padding: 15px 0 50px 0 !important;
    width: 46.258% !important;
  }
  .e-wallet-img0 {
    padding: 15px 0 50px 0 !important;
    width: 53.51% !important;
  }
  .e-wallet-wf-img {
    margin-top: 30px !important;
  }
  .e-wallet-wf-img1 {
    padding: 0 !important;
  }
  .e-wallet-wf-img2 {
    margin-top: 50px !important;
    padding: 0 !important;
  }
  .e-wallet-wf-img3 {
    margin-top: 0 !important;
    padding: 0 !important;
  }
  .e-wallet-wf-img4 {
    margin-top: 50px !important;
    padding: 0 !important;
  }
  .e-wallet-persol {
    position: relative;
    margin-top: 100px;
    padding: 10px !important;
  }
  .e-wallet-persol-cont {
    position: relative;
    background-color: #364556;
    border-radius: 20px 20px 20px 20px;
    min-height: 400px;
    margin: 10px;
  }
  .e-wallet-persol-cont h5 {
    position: relative;
    color: #ffffff;
    font-size: 22px;
    font-weight: 300;
    line-height: 40px;
    text-align: center;
    padding-top: 42px;
  }
  .e-wallet-persol-img {
    position: relative;
    margin-top: -350px !important;
    padding: 0 !important;
  }
  .e-wallet-persol-img1 {
    padding: 0 0 0 50px !important;
  }
  .e-wallet-persol-img2 {
    padding: 0 50px 0 0 !important;
  }
  .e-wallet-track {
    margin-top: 120px;
    padding: 0 10px !important;
  }
  .e-wallet-track-bg {
    background-color: #364556;
    border-radius: 20px 20px 20px 20px;
    margin: 10px;
  }
  .e-wallet-track-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 20px 0px 60px !important;
  }
  .e-wallet-track-cont h5 {
    color: #ffffff;
    font-size: 22px;
    font-weight: 500;
    line-height: 40px;
  }
  .e-wallet-track-cont p {
    color: #ffffff;
    font-size: 16px;
    font-weight: 300;
    line-height: 32px;
  }
  .e-wallet-track-img {
    margin-top: -110px;
  }
}

@media (max-width: 767px) {
  .e-wallet-img {
    padding: 10px 0 !important;
    width: 100% !important;
  }
  .e-wallet-img0 {
    padding: 0 0 50px 0 !important;
    width: 100% !important;
  }
  .e-wallet-wf-img {
    margin-top: 0 !important;
  }
  .e-wallet-wf-img1 {
    padding: 0 50px 0 50px !important;
  }
  .e-wallet-wf-img2 {
    margin-top: -50px !important;
    padding: 0 50px 0 50px !important;
  }
  .e-wallet-wf-img3 {
    margin-top: -50px !important;
    padding: 0 50px 0 50px !important;
  }
  .e-wallet-wf-img4 {
    margin-top: -50px !important;
    padding: 0 50px 0 50px !important;
  }
  .e-wallet-persol {
    position: relative;
    margin-top: 50px;
    padding: 10px !important;
  }
  .e-wallet-persol-cont {
    position: relative;
    background-color: #364556;
    border-radius: 20px 20px 20px 20px;
    min-height: 1180px;
    margin: 10px;
  }
  .e-wallet-persol-cont h5 {
    position: relative;
    color: #ffffff;
    font-size: 22px;
    font-weight: 300;
    line-height: 40px;
    text-align: center;
    padding-top: 42px;
  }
  .e-wallet-persol-img {
    position: relative;
    margin-top: -1160px !important;
    padding: 0 !important;
  }
  .e-wallet-persol-img p {
    color: #fff;
    text-align: center;
    font-family: "Manrope", Sans-serif;
    font-weight: 200;
    font-size: 20px;
    margin-top: -50px;
  }
  .e-wallet-persol-img1 {
    padding: 0 15px !important;
  }
  .e-wallet-persol-img2 {
    padding: 0 15px !important;
  }
  .e-wallet-lg {
    flex-direction: column-reverse;
  }
  .e-wallet-track {
    margin-top: 120px;
  }
  .e-wallet-track-bg {
    background-color: #364556;
    border-radius: 20px 20px 20px 20px;
    margin: 10px;
  }
  .e-wallet-track-cont {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .e-wallet-track-cont h5 {
    color: #ffffff;
    font-size: 22px;
    font-weight: 500;
    line-height: 40px;
  }
  .e-wallet-track-cont p {
    color: #ffffff;
    font-size: 16px;
    font-weight: 300;
    line-height: 32px;
  }
  .e-wallet-track-img {
    margin-top: -180px;
  }
}

/* --- FUT-Bank --- */

@media (min-width: 1024px) {
  .fut-bank {
    margin-top: 70px;
    padding-bottom: 60px !important;
  }
  .fut-bank-cont-position {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }
  .fut-bank-cont h3 {
    color: #000;
    font-size: 31px;
    font-weight: 300;
    line-height: 40px;
    margin-left: 10px;
  }
  .fut-bank-cont h4 {
    color: #000;
    font-size: 20px;
    line-height: 24px;
    margin-left: 10px;
  }
  /* .fut-bank-img {
    margin: -29px 30px 0px 30px;
  } */
  .fut-bank-img-wf {
    padding: 0 30px !important;
  }
}

@media (max-width: 1024px) {
  .fut-bank {
    margin-top: 50px;
    padding: 0 20px 10px 20px !important;
  }
  .fut-bank-cont-position {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .fut-bank-cont h3 {
    color: #000;
    font-size: 29px;
    font-weight: 300;
    line-height: 40px;
    margin-left: 10px;
  }
  .fut-bank-cont h4 {
    color: #000;
    font-size: 20px;
    line-height: 24px;
    margin-left: 10px;
  }
  .fut-bank-img-wf {
    margin-left: 10px !important;
    width: 90%;
  }
}

@media (max-width: 767px) {
  .fut-bank {
    margin-top: 130px;
    padding-bottom: 60px !important;
  }
  .fut-bank-cont-position {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .fut-bank-cont h3 {
    color: #000;
    font-size: 28px;
    font-weight: 300;
    line-height: 40px;
    margin-left: 10px;
  }
  .fut-bank-cont h4 {
    color: #000;
    font-size: 20px;
    line-height: 24px;
    margin-left: 10px;
  }
  .fut-bank-img-wf {
    margin-left: 30px !important;
    width: 83%;
  }
}

@media (min-width: 1024px) {
  .fut-bank-overview {
    margin: 40px 0 20px 0;
    padding: 12px !important;
  }
  .fut-bank-abov h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 5px;
  }
  .fut-bank-abov p {
    color: #000000;
    font-size: 16px;
    font-weight: 200;
    line-height: 32px;
    padding: 0px 120px 0px 0px;
  }
  .fut-bank-obj h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 5px;
  }
  .fut-bank-obj p {
    color: #000000;
    font-size: 16px;
    font-weight: 200;
    line-height: 32px;
    padding: 0px 120px 0px 0px;
  }
}

@media (max-width: 1024px) {
  .fut-bank-overview {
    margin-top: 30px;
    margin-bottom: 40px;
    padding: 0 30px !important;
  }
  .fut-bank-abov h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 5px;
  }
  .fut-bank-abov p {
    color: #000000;
    font-size: 16px;
    font-weight: 200;
    line-height: 32px;
    padding: 0px 55px 0px 0px;
  }
  .fut-bank-obj h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 5px;
  }
  .fut-bank-obj p {
    color: #000000;
    font-size: 16px;
    font-weight: 200;
    line-height: 32px;
    padding: 0px 55px 0px 0px;
  }
}

@media (max-width: 767px) {
  .fut-bank-overview {
    margin-top: 70px;
    margin-bottom: 40px;
  }
  .fut-bank-abov h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 5px;
  }
  .fut-bank-abov p {
    color: #000000;
    font-size: 16px;
    font-weight: 200;
    line-height: 32px;
    padding: 0px 10px 0px 0px;
  }
  .fut-bank-obj h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    margin-top: 30px;
    padding-bottom: 5px;
  }
  .fut-bank-obj p {
    color: #000000;
    font-size: 16px;
    font-weight: 200;
    line-height: 32px;
    padding: 0px 10px 0px 0px;
  }
}

@media (min-width: 1024px) {
  .fut-bank-user {
    background-color: #f3f3f3;
  }
  .fut-bank-user-cont {
    padding: 0 12px !important;
  }
  .fut-bank-user-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding: 60px 0 20px 0;
  }
  .fut-bank-user-img {
    padding: 50px 102px 80px 102px !important;
  }
  .fut-bank-user-img-rep {
    display: none;
  }
  .fut-bank-wf {
    margin-top: 70px;
    padding: 0 12px !important;
  }
  .fut-bank-wf h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 30px;
  }
  .fut-bank-design {
    margin-top: 70px;
    padding: 0 12px !important;
  }
  .fut-bank-design h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 40px;
  }
  .fut-bank-design h6 {
    color: #303030;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    padding-top: 30px;
  }
  .fut-bank-design-img {
    padding: 0% 40% 0% 0% !important;
  }
}

@media (max-width: 1024px) {
  .fut-bank-user {
    background-color: #f3f3f3;
  }
  .fut-bank-user-cont {
    padding: 0 30px !important;
  }
  .fut-bank-user-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding: 60px 0 30px 0;
  }
  .fut-bank-user-img {
    padding: 30px 102px 80px 102px !important;
  }
  .fut-bank-user-img-rep {
    display: none;
  }
  .fut-bank-wf {
    margin-top: 70px;
    padding: 0 30px !important;
  }
  .fut-bank-wf h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 30px;
  }
  .fut-bank-wf-img {
    padding: 10px 32px 10px 32px !important;
  }
  .fut-bank-design {
    margin-top: 70px;
    padding: 0 30px !important;
  }
  .fut-bank-design h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 40px;
  }
  .fut-bank-design h6 {
    color: #303030;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    padding-top: 30px;
  }
  .fut-bank-design-img {
    padding: 0% 40% 0% 0% !important;
  }
}

@media (max-width: 767px) {
  .fut-bank-user {
    background-color: #f3f3f3;
  }
  .fut-bank-user-cont {
    padding: 0 30px !important;
  }
  .fut-bank-user-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding: 60px 0 20px 0;
  }
  .fut-bank-user-img {
    display: none;
  }
  .fut-bank-user-img-rep {
    display: block;
    padding: 40px 0 60px 0 !important;
  }
  .fut-bank-wf {
    margin-top: 70px;
    padding: 0 30px !important;
  }
  .fut-bank-wf h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 30px;
  }
  .fut-bank-wf-img {
    padding: 20px 0 0 0 !important;
  }
  .fut-bank-design {
    margin-top: 70px;
    padding: 0 30px !important;
  }
  .fut-bank-design h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 40px;
  }
  .fut-bank-design h6 {
    color: #303030;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    padding-top: 20px;
  }
  .fut-bank-design-img {
    padding: 0% 40% 0% 0% !important;
  }
}

@media (min-width: 1024px) {
  .fut-bank-acc {
    margin-top: 50px;
    padding: 0 12px !important;
  }
  .fut-bank-acc h5 {
    color: #242424;
    font-size: 22px;
    font-weight: 200;
    line-height: 40px;
  }
  .fut-bank-des {
    margin-top: 42px;
    position: relative;
  }
  .fut-bank-des p {
    color: #262626;
    font-size: 22px;
    font-weight: 200;
    line-height: 40px;
  }
  .fut-bank-des-img {
    padding: 60px 165px 0px 165px;
  }
}

@media (max-width: 1024px) {
  .fut-bank-acc {
    margin-top: 50px;
    padding: 0 30px !important;
  }
  .fut-bank-acc h5 {
    color: #242424;
    font-size: 22px;
    font-weight: 200;
    line-height: 40px;
  }
  .fut-bank-des {
    margin-top: 80px;
    position: relative;
  }
  .fut-bank-des p {
    color: #262626;
    font-size: 22px;
    font-weight: 200;
    line-height: 40px;
  }
  .fut-bank-des-img {
    padding: 60px 165px 0px 165px;
  }
}

@media (max-width: 767px) {
  .fut-bank-acc {
    margin-top: 50px;
    padding: 0 30px !important;
  }
  .fut-bank-acc h5 {
    color: #242424;
    font-size: 22px;
    font-weight: 200;
    line-height: 40px;
  }
  .fut-bank-des {
    margin-top: 42px;
    padding: 0 30px !important;
    position: relative;
  }
  .fut-bank-des p {
    color: #262626;
    font-size: 22px;
    font-weight: 200;
    line-height: 40px;
  }
  .fut-bank-des-img {
    padding: 60px 0 0 0;
    /* z-index: 999; */
  }
}

@media (min-width: 1024px) {
  .fut-bank-bg {
    position: relative;
    text-align: center;
    background-color: #11b1ce;
    margin-top: -94px;
    z-index: -1;
  }
  .fut-bank-bill {
    padding: 171px 10px 10px 10px !important;
  }
  .fut-bank-bill h5 {
    color: #ffffff;
    font-size: 22px;
    font-weight: 400;
    line-height: 40px;
  }
  .fut-bank-bill p {
    color: #ffffff;
    font-size: 17px;
    font-weight: 200;
    line-height: 32px;
  }
  .fut-bank-pay {
    margin-top: 70px;
    padding-bottom: 70px !important;
  }
  .fut-bank-payment:nth-child(1) {
    display: flex;
    justify-content: flex-end;
    padding-right: 3.5rem !important;
  }
  .fut-bank-payment:nth-child(2) {
    display: flex;
    justify-content: center;
  }
  .fut-bank-payment:nth-child(3) {
    display: flex;
    justify-content: flex-start;
    padding-left: 2rem;
  }
  .fut-bank-icon {
    width: 70%;
    margin-bottom: 20px;
  }
  .fut-bank-icon0 {
    width: 42%;
    margin-bottom: 20px;
  }
  .fut-bank-icon1 {
    width: 35%;
    margin-bottom: 20px;
  }
  .fut-bank-pay-cont h5 {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
  }
  .fut-bank-pay-img {
    padding: 0px 0px 394px 230px;
  }
  .fut-bank-pay-img-rep {
    display: none;
  }
  .fut-bank-flow-img {
    margin-top: -247px;
    margin-bottom: 42px;
    padding: 0px 104px 0px 104px;
  }
}

@media (max-width: 1024px) {
  .fut-bank-bg {
    position: relative;
    text-align: center;
    background-color: #11b1ce;
    margin-top: -94px;
    padding-bottom: 160px;
    z-index: -1;
  }
  .fut-bank-bill {
    padding: 171px 10px 10px 10px !important;
  }
  .fut-bank-bill h5 {
    color: #ffffff;
    font-size: 22px;
    font-weight: 400;
    line-height: 40px;
  }
  .fut-bank-bill p {
    color: #ffffff;
    font-size: 17px;
    font-weight: 200;
    line-height: 32px;
  }
  .fut-bank-pay {
    margin-top: 70px;
    padding-bottom: 70px !important;
  }
  .fut-bank-payment:nth-child(1) {
    display: flex;
    justify-content: flex-end;
    padding-right: 3.5rem !important;
  }
  .fut-bank-payment:nth-child(2) {
    display: flex;
    justify-content: center;
  }
  .fut-bank-payment:nth-child(3) {
    display: flex;
    justify-content: flex-start;
    padding-left: 2rem;
  }
  .fut-bank-icon {
    width: 70%;
    margin-bottom: 20px;
  }
  .fut-bank-icon0 {
    width: 42%;
    margin-bottom: 20px;
  }
  .fut-bank-icon1 {
    width: 35%;
    margin-bottom: 20px;
  }
  .fut-bank-pay-cont h5 {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
  }
  .fut-bank-pay-img {
    padding: 0px 60px 84px 70px;
  }
  .fut-bank-pay-img-rep {
    display: none;
  }
  .fut-bank-flow-img {
    margin-top: -160px;
    margin-bottom: 42px;
    padding: 0px 104px 0px 104px;
  }
}

@media (max-width: 767px) {
  .fut-bank-bg {
    position: relative;
    text-align: center;
    background-color: #11b1ce;
    margin-top: -94px;
    z-index: -1;
    padding: 0 25px 185px 25px;
  }
  .fut-bank-bill {
    padding: 171px 10px 10px 10px !important;
  }
  .fut-bank-bill h5 {
    color: #ffffff;
    font-size: 22px;
    font-weight: 400;
    line-height: 40px;
  }
  .fut-bank-bill p {
    color: #ffffff;
    font-size: 16px;
    font-weight: 200;
    line-height: 32px;
  }
  .fut-bank-pay {
    margin-top: 50px;
    padding-bottom: 70px !important;
  }
  .fut-bank-payment:nth-child(1) {
    display: flex;
    justify-content: center;
    padding-right: 10px !important;
  }
  .fut-bank-payment:nth-child(2) {
    display: flex;
    justify-content: center;
    padding-top: 30px;
  }
  .fut-bank-payment:nth-child(3) {
    display: flex;
    justify-content: center;
    padding: 30px 10px 0 10px;
  }
  .fut-bank-icon {
    width: 70%;
    margin-bottom: 20px;
  }
  .fut-bank-icon0 {
    width: 42%;
    margin-bottom: 20px;
  }
  .fut-bank-icon1 {
    width: 35%;
    margin-bottom: 20px;
  }
  .fut-bank-pay-cont h5 {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
  }
  .fut-bank-pay-img {
    display: none;
  }
  .fut-bank-pay-img-rep {
    display: block;
    padding: 0 15px;
  }
  .fut-bank-flow-img {
    margin-top: -120px;
    margin-bottom: 42px;
    padding: 0px 18px;
  }
}

@media (min-width: 1024px) {
  .fut-bank-sec {
    margin-bottom: 42px;
  }
  .fut-bank-sec-img {
    padding: 0 60px 0 50px !important;
  }
  .fut-bank-sec-cont {
    padding-left: 50px !important;
  }
  .fut-bank-sec-cont h5 {
    color: #000000;
    font-size: 22px;
    font-weight: 500;
    line-height: 40px;
  }
  .fut-bank-sec-cont p {
    color: #000000;
    font-size: 17px;
    font-weight: 200;
    line-height: 32px;
  }
  .fut-bank-vdo-rep {
    display: none;
  }
  .fut-bank-ld {
    margin-top: 42px;
  }
  .fut-bank-ld-cont {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .fut-bank-ld-cont h5 {
    color: #000000;
    font-size: 22px;
    font-weight: 500;
    line-height: 40px;
  }
  .fut-bank-ld-cont p {
    color: #000000;
    font-size: 17px;
    font-weight: 200;
    line-height: 32px;
  }
  .fut-bank-video {
    margin: 42px 0 42px 0;
  }
}

@media (max-width: 1024px) {
  .fut-bank-sec {
    margin: 30px 0;
    padding: 0 30px !important;
  }
  .fut-bank-sec-img {
    padding: 0 10px 0 10px !important;
  }
  .fut-bank-sec-cont {
    padding-left: 20px !important;
  }
  .fut-bank-sec-cont h5 {
    color: #000000;
    font-size: 22px;
    font-weight: 500;
    line-height: 40px;
  }
  .fut-bank-sec-cont p {
    color: #000000;
    font-size: 16px;
    font-weight: 200;
    line-height: 32px;
  }
  .fut-bank-vdo {
    display: none;
  }
  .fut-bank-vdo-rep {
    margin-top: 60px;
  }
  .fut-bank-ld {
    margin-top: 42px;
    padding: 0 30px !important;
  }
  .fut-bank-ld-cont {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .fut-bank-ld-cont h5 {
    color: #000000;
    font-size: 22px;
    font-weight: 500;
    line-height: 40px;
  }
  .fut-bank-ld-cont p {
    color: #000000;
    font-size: 17px;
    font-weight: 200;
    line-height: 32px;
  }
  .fut-bank-video {
    margin: 42px 0 42px 0;
  }
}

@media (max-width: 767px) {
  .fut-bank-sec {
    margin-bottom: 42px;
  }
  .fut-bank-sec-row {
    flex-direction: column-reverse;
  }
  .fut-bank-sec-img {
    padding: 0 20px !important;
  }
  .fut-bank-sec-cont {
    padding-left: 0px !important;
  }
  .fut-bank-sec-cont h5 {
    color: #000000;
    font-size: 22px;
    font-weight: 500;
    line-height: 40px;
    padding-top: 40px;
  }
  .fut-bank-sec-cont p {
    color: #000000;
    font-size: 17px;
    font-weight: 200;
    line-height: 32px;
  }
  .fut-bank-vdo {
    display: none;
  }
  .fut-bank-vdo-rep {
    margin-top: 40px;
  }
  .fut-bank-ld {
    margin-top: 42px;
    padding: 0 30px !important;
  }
  .fut-bank-ld-cont {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .fut-bank-ld-cont h5 {
    color: #000000;
    font-size: 22px;
    font-weight: 500;
    line-height: 40px;
    margin-top: 30px;
  }
  .fut-bank-ld-cont p {
    color: #000000;
    font-size: 17px;
    font-weight: 200;
    line-height: 32px;
  }
  .fut-bank-video {
    margin: 42px 0 42px 0;
  }
}

/* --- sunny bee ---*/

@media (min-width: 1024px) {
  .sunny-bee {
    margin-top: 80px;
    padding-bottom: 60px !important;
  }
  .sunny-bee-cont-position {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .sunny-bee-cont h3 {
    color: #000;
    font-size: 32px;
    font-weight: 300;
    line-height: 40px;
    margin-left: 10px;
  }
  .sunny-bee-cont h4 {
    color: #000;
    font-size: 20px;
    line-height: 24px;
    margin-left: 10px;
  }
  .sunny-bee-img-wf {
    margin-left: 30px !important;
  }
  .sunny-bee-obj {
    margin-top: 100px;
    margin-bottom: 80px;
  }
  .sunny-bee-obj-text h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
  }
  .sunny-bee-obj-text p {
    color: #000;
    font-size: 16px;
    font-weight: 200;
    line-height: 32px;
    padding-top: 5px;
    padding-right: 120px;
  }
  .sunny-bee-desin-text h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 30px;
  }
  .sunnybee-desin-ul h5 {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
  }
  .sunnybee-desin-li {
    padding-left: 20px !important;
  }
  .sunnybee-desin-li li {
    color: #000000;
    font-size: 16px;
    font-weight: 300;
    line-height: 34px;
  }
  .sunnybee-desin-li li::marker {
    font-size: 20px !important;
  }
}

@media (max-width: 1024px) {
  .sunny-bee {
    margin-top: 80px;
    padding: 0 20px 60px 20px !important;
  }
  .sunny-bee-cont-position {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .sunny-bee-cont h3 {
    color: #000;
    font-size: 30px;
    font-weight: 300;
    line-height: 40px;
    margin-left: 10px;
  }
  .sunny-bee-cont h4 {
    color: #000;
    font-size: 20px;
    line-height: 24px;
    margin-left: 10px;
  }
  .sunny-bee-img-wf {
    margin-left: 20px !important;
    width: 83%;
  }
  .sunny-bee-obj {
    margin-top: 80px;
    margin-bottom: 80px;
    padding: 0 30px !important;
  }
  .sunny-bee-obj-text h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
  }
  .sunny-bee-obj-text p {
    color: #000;
    font-size: 16px;
    font-weight: 200;
    line-height: 32px;
    padding-top: 5px;
    padding-right: 50px;
  }
  .sunny-bee-desin {
    padding: 0 18px;
  }
  .sunny-bee-desin-text h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 30px;
  }
  .sunnybee-desin-ul h5 {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
  }
  .sunnybee-desin-ul {
    padding: 0 6px 0 12px !important;
  }
  .sunnybee-desin-li {
    padding-left: 20px !important;
  }
  .sunnybee-desin-li li {
    color: #000000;
    font-size: 16px;
    font-weight: 200;
    line-height: 34px;
  }
  .sunnybee-desin-li li::marker {
    font-size: 20px !important;
  }
}

@media (max-width: 767px) {
  .flyet-card-reverse {
    flex-direction: column;
  }

  .sunny-bee {
    margin-top: 100px;
    padding-bottom: 60px !important;
  }
  .sunny-bee-cont-position {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .sunny-bee-cont h3 {
    color: #000;
    font-size: 28px;
    font-weight: 300;
    line-height: 40px;
    margin-left: 10px;
  }
  .sunny-bee-cont h4 {
    color: #000;
    font-size: 20px;
    line-height: 24px;
    margin-left: 10px;
  }
  .sunny-bee-img-wf {
    margin-top: 40px;
    margin-left: 30px !important;
  }
  .sunny-bee-obj {
    margin-top: 80px;
    margin-bottom: 40px;
  }
  .sunny-bee-obj-text h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
  }
  .sunny-bee-obj-text p {
    color: #000;
    font-size: 16px;
    font-weight: 200;
    line-height: 32px;
    padding-top: 5px;
    padding-right: 10px;
    padding-bottom: 20px;
  }
  .sunny-bee-desin {
    padding: 0 18px;
  }
  .sunny-bee-desin-text h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 30px;
  }
  .sunnybee-desin-ul h5 {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
  }
  .sunnybee-desin-ul {
    padding: 12px !important;
  }
  .sunnybee-desin-li {
    padding-left: 20px !important;
  }
  .sunnybee-desin-li li {
    color: #000000;
    font-size: 16px;
    font-weight: 300;
    line-height: 34px;
  }
  .sunnybee-desin-li li::marker {
    font-size: 20px !important;
  }
}

@media (min-width: 1024px) {
  .sunnybee-card-se {
    background-color: #f0af18;
    margin-top: 160px;
    margin-bottom: 0px;
    position: relative;
  }
  .sunnybee-card-img {
    margin: -85px 0 0 0 !important;
    padding: 10px !important;
  }
  .sunnybee-card-cont h5 {
    display: block;
    position: relative;
    color: #000000;
    font-size: 32px;
    font-weight: 300;
    line-height: 48px;
    text-align: center;
    padding: 39px 0px 73px 0px;
  }
}

@media (max-width: 1024px) {
  .sunnybee-card-se {
    background-color: #f0af18;
    margin-top: 140px;
    margin-bottom: 0px;
    position: relative;
  }
  .sunnybee-card-img {
    margin: -85px 0 0 0 !important;
    padding: 18px !important;
  }
  .sunnybee-card-cont h5 {
    display: block;
    position: relative;
    color: #000000;
    font-size: 30px;
    font-weight: 200;
    line-height: 48px;
    text-align: center;
    padding: 15px 0px 65px 0px;
  }
}

@media (max-width: 767px) {
  .sunnybee-card-se {
    background-color: #f0af18;
    margin-top: 130px;
    margin-bottom: 0px;
    position: relative;
  }
  .sunnybee-card-img {
    margin: -85px 0 0 0 !important;
    padding: 18px 18px 8px 18px !important;
  }
  .sunnybee-card-cont h5 {
    display: block;
    position: relative;
    color: #000000;
    font-size: 16px;
    font-weight: 200;
    line-height: 48px;
    text-align: center;
    padding: 0 0px 10px 0px;
  }
}

@media (min-width: 1024px) {
  .sunnybee-usrflow {
    margin-top: 60px;
  }
  .sunnybee-usrflow-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 25px;
  }
  .sunnybee-usrflow-text p {
    color: #000000;
    font-size: 16px;
    font-weight: 200;
    line-height: 32px;
    padding-left: 25px;
  }
}

@media (max-width: 1024px) {
  .sunnybee-usrflow {
    margin-top: 60px;
    padding: 18px !important;
  }
  .sunnybee-usrflow-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 25px;
  }
  .sunnybee-usrflow-text p {
    color: #000000;
    font-size: 16px;
    font-weight: 200;
    line-height: 32px;
    padding: 0 20px 0 25px;
  }
}

@media (max-width: 767px) {
  .sunnybee-usrflow {
    margin-top: 60px;
  }
  .sunnybee-usrflow-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 25px;
  }
  .sunnybee-usrflow-text p {
    color: #000000;
    font-size: 16px;
    font-weight: 200;
    line-height: 32px;
    padding-left: 0;
  }
}

@media (min-width: 1024px) {
  .sunnybee-wf {
    margin-top: 50px;
  }
  .sunnybee-wf-cont {
    padding: 0 12px !important;
  }
  .sunnybee-wf-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 30px;
  }
  .sunnybee-wf-img {
    margin-top: 90px !important;
  }
  .sunnybee-wf-img2 {
    margin-top: 97px;
  }
  .sunnybee-wf-img4 {
    margin-top: 97px;
  }
}

@media (max-width: 1024px) {
  .sunnybee-wf {
    margin-top: 50px;
    padding: 0 18px !important;
  }
  .sunnybee-wf-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 30px;
  }
  .sunnybee-wf-img {
    margin-top: 50px !important;
  }
  .sunnybee-wf-img2 {
    margin-top: 97px;
  }
  .sunnybee-wf-img4 {
    margin-top: 97px;
  }
}

@media (max-width: 767px) {
  .sunnybee-wf {
    margin-top: 50px;
  }
  .sunnybee-wf-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 30px;
  }
  .sunnybee-wf-img {
    margin-top: 50px !important;
  }
  .sunnybee-wf-img1 {
    padding: 0 50px 0 50px;
  }
  .sunnybee-wf-img2 {
    margin-top: 0;
    padding: 30px 50px 0 50px;
  }
  .sunnybee-wf-img3 {
    padding: 30px 50px 0 50px;
  }
  .sunnybee-wf-img4 {
    margin-top: 0;
    padding: 30px 50px 0 50px;
  }
}

@media (min-width: 1024px) {
  .sunnybee-desinsys {
    margin-top: 60px;
  }
  .sunnybee-desinsys-cont h5 {
    color: #0c0d15;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    font-style: normal;
    line-height: 21px;
    padding-bottom: 20px;
  }
  .sunnybee-desinsys-img {
    padding: 1% 35% 1% 1% !important;
  }
  .sunnybee-desinsys-img0 {
    margin-top: 59px !important;
  }
}

@media (max-width: 1024px) {
  .sunnybee-desinsys {
    margin-top: 60px;
    padding: 18px !important;
  }
  .sunnybee-desinsys-cont h5 {
    color: #0c0d15;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    font-style: normal;
    line-height: 21px;
    padding-bottom: 20px;
  }
  .sunnybee-desinsys-img {
    padding: 1% 35% 1% 1% !important;
  }
  .sunnybee-desinsys-img0 {
    margin-top: 59px !important;
  }
}

@media (max-width: 767px) {
  .sunnybee-desinsys {
    margin-top: 60px;
  }
  .sunnybee-desinsys-cont h5 {
    color: #0c0d15;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    font-style: normal;
    line-height: 21px;
    padding-bottom: 20px;
  }
  .sunnybee-desinsys-img {
    padding: 1% 35% 5% 1% !important;
  }
  .sunnybee-desinsys-img0 {
    margin-top: 59px !important;
  }
}

@media (min-width: 1024px) {
  .sunnybee-anim {
    margin-top: 75px;
    margin-bottom: 30px;
  }
  .sunnybee-anim-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 25px;
  }
  .sunnybee-video1 {
    margin: 0% 5% 0% 35%;
  }
  .sunnybee-video2 {
    margin: 0% 35% 0% 5%;
  }
  .sunnybee-anim-video {
    margin-top: 75px !important;
    padding-bottom: 120px;
  }
  .sunnybee-video1-h {
    max-height: 600px;
    overflow: hidden;
    border-radius: 10px;
    filter: brightness(100%) contrast(100%) saturate(100%) blur(0px)
      hue-rotate(0deg);
  }
  .sunnybee-anim-text h4 {
    color: #000000;
    font-size: 22px;
    font-weight: 500;
    line-height: 40px;
  }
  .sunnybee-anim-text p {
    color: #000000;
    font-size: 16px;
    font-weight: 200;
    line-height: 32px;
  }
  .sunnybee-anim-align-rep1:nth-child(4) {
    padding-bottom: 30px !important;
  }
}

@media (max-width: 1024px) {
  .sunnybee-anim {
    margin-top: 40px;
    margin-bottom: 0;
    padding: 18px !important;
  }
  .sunnybee-anim-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 25px;
  }
  .sunnybee-video1 {
    margin: 0% 5% 0% 30%;
  }
  .sunnybee-video2 {
    margin: 0% 30% 0% 5%;
  }
  .sunnybee-anim-video {
    margin-top: 75px !important;
    padding-bottom: 120px;
  }
  .sunnybee-video1-h {
    max-height: 400px;
    overflow: hidden;
    border-radius: 10px;
    filter: brightness(100%) contrast(100%) saturate(100%) blur(0px)
      hue-rotate(0deg);
  }
  .sunnybee-anim-text {
    padding: 0 45px 0 35px !important;
  }
  .sunnybee-anim-text h4 {
    color: #000000;
    font-size: 22px;
    font-weight: 500;
    line-height: 40px;
  }
  .sunnybee-anim-text p {
    color: #000000;
    font-size: 16px;
    font-weight: 200;
    line-height: 32px;
  }
}

@media (max-width: 767px) {
  .sunnybee-anim {
    margin-top: 75px;
    margin-bottom: 0;
    padding: 15px !important;
  }
  .sunnybee-anim-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 25px;
  }
  .sunnybee-video1 {
    margin: 0% 8% 0% 8%;
  }
  .sunnybee-video2 {
    margin: 15% 8% 0% 8%;
  }
  .sunnybee-anim-video {
    margin-top: 75px !important;
    padding-bottom: 50px;
  }
  .sunnybee-video1-h {
    max-height: 550px;
    overflow: hidden;
    border-radius: 10px;
    filter: brightness(100%) contrast(100%) saturate(100%) blur(0px)
      hue-rotate(0deg);
  }
  .sunnybee-anim-text {
    padding: 0 !important;
  }
  .sunnybee-anim-text h4 {
    color: #000000;
    font-size: 22px;
    font-weight: 500;
    line-height: 40px;
  }
  .sunnybee-anim-text p {
    color: #000000;
    font-size: 16px;
    font-weight: 200;
    line-height: 32px;
  }
  .sunnybee-anim-align-rep {
    flex-direction: column-reverse;
    padding-bottom: 40px;
  }
  .sunnybee-anim-align-rep1 {
    padding-bottom: 40px;
  }
  .sunnybee-anim-align-rep1:nth-child(4) {
    padding-bottom: 10px;
  }
  .sunnybee-anim-img {
    padding: 10px 0 30px 0 !important;
  }
}

/* --- Gbr Tmt --- */

.gbr-img {
  margin-bottom: 60px;
}
.gbr-tmt {
  margin: 80px 0 40px 0;
  padding: 0 10px !important;
}
.grey-gbr {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 27.07%,
    #f5f5f5 27.08%
  );
  text-align: center;
  padding-bottom: 80px !important;
}
.grey-gbr img {
  width: 70%;
}
.orange-gbr {
  background-image: url(https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/dc9bcbe4-516516-1.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0px !important;
  margin-top: 80px;
  text-align: center;
}
.orange-gbr2 {
  opacity: 1.8;
  background-color: transparent;
  background-image: linear-gradient(360deg, #f5821fd9 0%, #f15e22d9 100%);
  padding: 84px 0px !important;
}
.orange-gbr2 img {
  opacity: 1;
  width: 70%;
}
@media (max-width: 1024px) {
  .gbr-img {
    margin-bottom: 60px;
  }
  .gbr-tmt {
    margin: 80px 0 40px 0;
    padding: 0 30px !important;
  }
  .grey-gbr {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 46.07%,
      #f5f5f5 27.08%
    );
    text-align: center;
    padding-bottom: 60px !important;
  }
  .grey-gbr img {
    width: 70%;
  }
  .orange-gbr {
    background-image: url(https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/dc9bcbe4-516516-1.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0px !important;
    margin-top: 80px;
    text-align: center;
  }
  .orange-gbr2 {
    opacity: 1.8;
    background-color: transparent;
    background-image: linear-gradient(360deg, #f5821fd9 0%, #f15e22d9 100%);
    padding: 44px 0px !important;
  }
  .orange-gbr2 img {
    opacity: 1;
    width: 90%;
  }
}
@media (max-width: 767px) {
  .gbr-img {
    margin-bottom: 60px;
  }
  .gbr-tmt {
    margin: 80px 0 40px 0;
    padding: 0 22px !important;
  }
  .grey-gbr {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 27.07%,
      #f5f5f5 27.08%
    );
    text-align: center;
    padding-bottom: 40px !important;
  }
  .grey-gbr img {
    width: 90%;
  }
  .orange-gbr {
    background-image: url(https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/dc9bcbe4-516516-1.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0px !important;
    margin-top: 80px;
    text-align: center;
  }
  .orange-gbr2 {
    opacity: 1.8;
    background-color: transparent;
    background-image: linear-gradient(360deg, #f5821fd9 0%, #f15e22d9 100%);
    padding: 44px 0px !important;
  }
  .orange-gbr2 img {
    opacity: 1;
    width: 90%;
  }
}

/* --- Kokum Store --- */

.ks-ban-bg {
  background-color: transparent;
  background-image: linear-gradient(
    180deg,
    rgba(170, 129, 57, 0.16) 64.58%,
    rgba(170, 129, 57, 0) 100%
  );
  position: relative;
  margin-top: 40px;
  padding-top: 30px;
}

@media (max-width: 1024px) {
  .ks-ban-bg {
    background-color: transparent;
    background-image: linear-gradient(
      180deg,
      rgba(170, 129, 57, 0.16) 64.58%,
      rgba(170, 129, 57, 0) 100%
    );
    position: relative;
    margin-top: 40px;
    padding: 30px 20px 0 20px;
  }
}
@media (max-width: 767px) {
  .ks-ban-bg {
    background-color: transparent;
    background-image: linear-gradient(
      180deg,
      rgba(170, 129, 57, 0.16) 64.58%,
      rgba(170, 129, 57, 0) 100%
    );
    position: relative;
    margin-top: 40px;
    padding-top: 30px;
  }
}

.ks-img-bgb {
  position: relative;
  background: linear-gradient(
    0deg,
    rgba(170, 129, 57, 0.16) 0%,
    rgba(170, 129, 57, 0) 100%
  );
  margin: 80px 0;
  padding: 50px 0px 84px 0px;
  text-align: center;
}

@media (max-width: 1024px) {
  .ks-img-bgb {
    position: relative;
    background: linear-gradient(
      0deg,
      rgba(170, 129, 57, 0.16) 0%,
      rgba(170, 129, 57, 0) 100%
    );
    margin: 80px 0;
    padding: 40px 20px;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .ks-img-bgb {
    position: relative;
    background: linear-gradient(
      0deg,
      rgba(170, 129, 57, 0.16) 0%,
      rgba(170, 129, 57, 0) 100%
    );
    margin: 80px 0;
    padding: 40px 0px;
    text-align: center;
  }
}

/* --- Loan origination system --- */

@media (min-width: 1024px) {
  .los-dash-img {
    margin-top: 120px;
    padding-bottom: 60px !important;
  }
  .los-wf-img {
    margin: 10px 360px 0px 50px;
  }
  .los-wf-img0 {
    margin: 10px 50px 0px 360px;
  }
  .los-cus-dash-cont {
    position: relative;
    background-color: #f4f4f4;
    border-radius: 20px 20px 20px 20px;
    text-align: center;
  }
  .los-cus-dash-cont h5 {
    color: #2d2d2d;
    font-size: 30px;
    font-weight: 200;
    line-height: 48px;
    padding-top: 60px;
  }
  .los-cus-dash-cont p {
    color: #000000;
    font-size: 22px;
    font-weight: 200;
    line-height: 32px;
  }
  .los-cus-dash-img {
    width: 90%;
    margin: 0px 0px -250px 0px;
  }
  .los-cus-dash-sec {
    margin-top: 300px;
  }
  .los-cus-sec-img {
    padding: 25px !important;
  }
  .los-cus-sec-cont0 {
    margin-top: 100px;
  }
  .los-cus-sec-cont1 {
    margin-top: 110px;
    margin-bottom: 32px;
  }
}

@media (max-width: 1024px) {
  .los-dash-img {
    margin-top: 120px;
    padding: 0 20px 60px 20px !important;
  }
  .los-wf-img {
    margin: 10px 200px 0px 50px;
  }
  .los-wf-img0 {
    margin: 10px 50px 0px 200px;
  }
  .los-cus-dash {
    padding: 30px !important;
  }
  .los-cus-dash-cont {
    position: relative;
    background-color: #f4f4f4;
    border-radius: 20px 20px 20px 20px;
    text-align: center;
  }
  .los-cus-dash-cont h5 {
    color: #2d2d2d;
    font-size: 30px;
    font-weight: 200;
    line-height: 48px;
    padding-top: 60px;
  }
  .los-cus-dash-cont p {
    color: #000000;
    font-size: 22px;
    font-weight: 200;
    line-height: 32px;
  }
  .los-cus-dash-img {
    width: 90%;
    margin: 0px 0px -250px 0px;
  }
  .los-cus-dash-sec {
    margin-top: 250px;
  }
  .los-cus-sec-img {
    padding: 25px !important;
  }
  .los-cus-sec-cont0 {
    margin-top: 70px;
  }
  .los-cus-sec-cont1 {
    margin-top: 80px;
    margin-bottom: 32px;
  }
}

@media (max-width: 767px) {
  .los-dash-img {
    margin-top: 120px;
    padding-bottom: 10px !important;
  }
  .los-wf-img {
    margin: 0;
  }
  .los-wf-img0 {
    margin: 0;
  }
  .los-cus-dash-cont {
    position: relative;
    background-color: #f4f4f4;
    border-radius: 20px 20px 20px 20px;
    text-align: center;
  }
  .los-cus-dash-cont h5 {
    color: #2d2d2d;
    font-size: 28px;
    font-weight: 200;
    line-height: 48px;
    padding-top: 40px;
  }
  .los-cus-dash-cont p {
    color: #000000;
    font-size: 16px;
    font-weight: 200;
    line-height: 32px;
  }
  .los-cus-dash-img {
    width: 90%;
    margin: 0px 10px 15px 10px;
  }
  .los-cus-dash-sec {
    margin-top: 60px;
  }
  .los-cus-sec-img {
    padding: 10px !important;
  }
  .los-cus-sec-cont {
    padding: 30px !important;
  }
  .los-cus-sec-cont0 {
    margin-top: 30px;
    padding: 0 30px !important;
  }
  .los-cus-sec-cont1 {
    margin-top: 60px;
    margin-bottom: 32px;
    padding: 0 30px !important;
  }
  .los-cus-sec-col {
    flex-direction: column-reverse;
  }
}

/* --- Private earth --- */

@media (min-width: 1024px) {
  .private-earth-ban-bg {
    background-color: transparent;
    background-image: linear-gradient(180deg, #ffffff 40%, #fafcf2 40%);
    position: relative;
    margin-top: 24px;
  }
  .private-earth-ban {
    padding: 10px !important;
  }
  .private-earth-ban-img {
    margin: 0px 0px 0px -35px;
  }
  .private-earth-img-bg {
    position: relative;
    background: linear-gradient(
      180deg,
      rgba(176, 209, 53, 0.09) 0%,
      rgba(176, 209, 53, 0) 79.61%
    );
    margin-top: 80px;
    padding: 50px 0px 84px 0px;
    text-align: center;
  }
  .private-earth-img {
    margin-top: 30px;
    text-align: center;
  }
  .private-earth-img-bgb {
    position: relative;
    background: linear-gradient(
      360deg,
      rgba(176, 209, 53, 0.1) 0%,
      rgba(176, 209, 53, 0) 79.61%
    );
    margin-top: 80px;
    padding: 50px 0px 84px 0px;
    text-align: center;
  }
}

@media (max-width: 1024px) {
  .private-earth-ban-bg {
    background-color: transparent;
    background-image: linear-gradient(180deg, #ffffff 40%, #fafcf2 40%);
    position: relative;
  }
  .private-earth-ban {
    padding: 10px !important;
  }
  .private-earth-ban-img {
    margin: 24px 0px 0px 0;
  }
  .private-earth-img-bg {
    position: relative;
    background: linear-gradient(
      180deg,
      rgba(176, 209, 53, 0.09) 0%,
      rgba(176, 209, 53, 0) 79.61%
    );
    margin-top: 80px;
    padding: 40px 8px 20px 8px;
    text-align: center;
  }
  .private-earth-img {
    margin-top: 30px;
    text-align: center;
  }
  .private-earth-img-bgb {
    position: relative;
    background: linear-gradient(
      360deg,
      rgba(176, 209, 53, 0.1) 0%,
      rgba(176, 209, 53, 0) 79.61%
    );
    margin-top: 40px;
    padding: 30px 8px 84px 8px;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .private-earth-ban-bg {
    background-color: transparent;
    background-image: linear-gradient(180deg, #ffffff 40%, #fafcf2 40%);
    position: relative;
  }
  .private-earth-ban {
    padding: 18px !important;
  }
  .private-earth-ban-img {
    margin: 24px 0px 0px 0;
  }
  .private-earth-img-bg {
    position: relative;
    background: linear-gradient(
      180deg,
      rgba(176, 209, 53, 0.09) 0%,
      rgba(176, 209, 53, 0) 79.61%
    );
    margin-top: 40px;
    padding: 30px 0 10px 0;
    text-align: center;
  }
  .private-earth-img {
    margin-top: 30px;
    text-align: center;
  }
  .private-earth-img-bgb {
    position: relative;
    background: linear-gradient(
      360deg,
      rgba(176, 209, 53, 0.1) 0%,
      rgba(176, 209, 53, 0) 79.61%
    );
    margin-top: 40px;
    padding: 20px 0px 44px 0px;
    text-align: center;
  }
}

/* --- sequence lounge ---*/

@media (min-width: 1024px) {
  .seq-lounge-head {
    margin-top: 140px;
    padding: 0 20px;
  }
  .seq-lounge-head h2 {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.3px;
    padding: 0 10px;
  }
  .seq-lounge-head h3 {
    font-size: 32px;
    font-weight: 300;
    line-height: 40px;
    letter-spacing: -0.3px;
    padding: 0 10px;
  }
  .seq-lounge-bgcl {
    background: linear-gradient(
      180deg,
      rgba(217, 217, 217, 0.16) 64.58%,
      rgba(215, 215, 215, 0) 100%
    );
    margin-top: 42px;
  }
  .seq-lounge-bgcl1 {
    background: linear-gradient(
      0deg,
      rgba(217, 217, 217, 0.16) 0%,
      rgba(215, 215, 215, 0) 100%
    );
  }
  .seq-lounge-pad {
    padding: 100px 65px 60px 70px;
  }
  .seq-lounge-img {
    padding: 10px 20px 10px 10px;
  }
}

@media (max-width: 1024px) {
  .seq-lounge-head {
    margin-top: 140px;
    padding: 0 8px;
  }
  .seq-lounge-head h2 {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.3px;
    padding: 0 10px;
  }
  .seq-lounge-head h3 {
    font-size: 30px;
    font-weight: 300;
    line-height: 40px;
    letter-spacing: -0.3px;
    padding: 0 10px;
  }
  .seq-lounge-bgcl {
    background: linear-gradient(
      180deg,
      rgba(217, 217, 217, 0.16) 64.58%,
      rgba(215, 215, 215, 0) 100%
    );
    margin-top: 42px;
  }
  .seq-lounge-bgcl1 {
    background: linear-gradient(
      0deg,
      rgba(217, 217, 217, 0.16) 0%,
      rgba(215, 215, 215, 0) 100%
    );
  }
  .seq-lounge-pad {
    padding: 10px 50px;
  }
  .seq-lounge-img {
    padding: 10px 18px;
  }
}
@media (max-width: 767px) {
  .seq-lounge-head {
    margin-top: 130px;
    padding: 0 8px;
  }
  .seq-lounge-head h2 {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.3px;
    padding: 0 10px;
  }
  .seq-lounge-head h3 {
    font-size: 28px;
    font-weight: 300;
    line-height: 40px;
    letter-spacing: -0.3px;
    padding: 0 10px;
  }
  .seq-lounge-bgcl {
    background: linear-gradient(
      180deg,
      rgba(217, 217, 217, 0.16) 64.58%,
      rgba(215, 215, 215, 0) 100%
    );
    margin-top: 42px;
  }
  .seq-lounge-bgcl1 {
    background: linear-gradient(
      0deg,
      rgba(217, 217, 217, 0.16) 0%,
      rgba(215, 215, 215, 0) 100%
    );
  }
  .seq-lounge-pad {
    padding: 0 18px 10px 18px;
  }
  .seq-lounge-img {
    padding: 12px 18px;
  }
}

@media (min-width: 1024px) {
  .seq-lounge-obj {
    margin: 70px 0 40px 0;
  }
  .seq-lounge-obj-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 20px;
  }
  .seq-lounge-obj-cont p {
    color: #000;
    font-size: 16px;
    font-weight: 200;
    /* letter-spacing: 1.5px; */
    line-height: 32px;
    padding-top: 10px;
    padding-right: 170px;
  }
}

@media (max-width: 1024px) {
  .seq-lounge-obj {
    margin: 40px 18px 40px 18px;
  }
  .seq-lounge-obj-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 20px;
  }
  .seq-lounge-obj-cont p {
    color: #000;
    font-size: 16px;
    font-weight: 200;
    /* letter-spacing: 1.5px; */
    line-height: 32px;
    padding-top: 10px;
    padding-right: 60px;
  }
}

@media (max-width: 767px) {
  .seq-lounge-obj {
    margin: 40px 18px 20px 18px;
  }
  .seq-lounge-obj-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 20px;
  }
  .seq-lounge-obj-cont p {
    color: #000;
    font-size: 16px;
    font-weight: 200;
    /* letter-spacing: 1.5px; */
    line-height: 32px;
    padding-top: 10px;
    padding-right: 0;
  }
}

@media (min-width: 1024px) {
  .seq-lounge-res {
    margin: 70px 0 40px 0;
  }
  .seq-lounge-res-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 20px;
  }
  .seq-lounge-res-cont p {
    color: #000;
    font-size: 16px;
    font-weight: 200;
    /* letter-spacing: 1.5px; */
    line-height: 32px;
    padding-top: 10px;
    padding-right: 60px;
  }
}

@media (max-width: 1024px) {
  .seq-lounge-res {
    margin: 70px 18px 40px 18px;
  }
  .seq-lounge-res-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 20px;
  }
  .seq-lounge-res-cont p {
    color: #000;
    font-size: 16px;
    font-weight: 200;
    /* letter-spacing: 1.5px; */
    line-height: 32px;
    padding-top: 10px;
    padding-right: 10px;
  }
  .seq-lounge-res-img {
    padding-top: 40px;
  }
}

@media (max-width: 767px) {
  .seq-lounge-res {
    margin: 40px 18px 20px 18px;
  }
  .seq-lounge-res-img {
    padding-top: 10px;
  }
  .seq-lounge-res-cont p {
    padding-right: 32px;
  }
}

@media (min-width: 1024px) {
  .seq-lounge-ul {
    display: flex;
    margin-top: 40px;
  }
  .seq-lounge-li {
    padding-left: 20px !important;
  }
  .seq-lounge-li li {
    color: #000;
    font-size: 16px;
    font-weight: 300;
    line-height: 36px;
    margin-right: 180px;
  }
  .seq-lounge-li li::marker {
    font-size: 20px;
  }
}

@media (max-width: 1024px) {
  .seq-lounge-ul {
    display: flex;
    margin-top: 40px;
  }
  .seq-lounge-li {
    padding-left: 20px !important;
  }
  .seq-lounge-li li {
    color: #000;
    font-size: 16px;
    font-weight: 300;
    line-height: 36px;
    margin-right: 80px;
  }
  .seq-lounge-li li::marker {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .seq-lounge-ul {
    display: block;
    margin-top: 40px;
  }
  .seq-lounge-li {
    padding-left: 20px !important;
  }
  .seq-lounge-li- {
    margin-top: -16px;
  }
  .seq-lounge-li li {
    color: #000;
    font-size: 16px;
    font-weight: 300;
    line-height: 36px;
    margin-right: 10px;
  }
  .seq-lounge-li li::marker {
    font-size: 20px;
  }
}

@media (min-width: 1024px) {
  .seq-lounge-wf {
    margin: 80px 0 40px 0;
  }
  .seq-lounge-wf-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 40px;
  }
  .img-wf {
    margin-top: 100px;
    margin-left: 20px !important;
  }

  .seq-lounge-mob {
    margin-top: 60px;
    margin-bottom: 20px;
  }
}

@media (max-width: 1024px) {
  .seq-lounge-wf {
    margin: 80px 18px 40px 18px;
  }
  .seq-lounge-wf-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 40px;
  }
  .img-wf {
    margin-top: 100px;
    margin-left: 20px !important;
  }

  .seq-lounge-mob {
    margin: 10px 0 30px 0;
    padding: 0 28px !important;
  }
}

@media (max-width: 767px) {
  .seq-lounge-wf {
    margin: 80px 18px 40px 18px;
  }
  .seq-lounge-wf-cont h5 {
    color: #2d2d2d;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    padding-bottom: 40px;
  }
  .img-wf {
    margin-top: 100px;
    margin-left: 20px !important;
  }

  .seq-lounge-mob {
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

/* --- Xanadu --- */

.xanadu-bgcl {
  background: linear-gradient(
    180deg,
    rgba(217, 217, 217, 0.16) 64.58%,
    rgba(215, 215, 215, 0) 100%
  );
  margin-top: 42px;
  padding: 40px 0px 0px 0px;
}
.xan-img-top-pad {
  margin-top: 80px;
}
.xan-blue {
  background-color: #22415f;
  padding: 84px 0px;
}
.xan-last {
  margin-top: 60px;
}
@media (max-width: 1024px) {
  .xanadu-bgcl {
    background: linear-gradient(
      180deg,
      rgba(217, 217, 217, 0.16) 64.58%,
      rgba(215, 215, 215, 0) 100%
    );
    margin-top: 42px;
    padding: 20px 0px 0px 0px;
  }
  .xan-blue {
    background-color: #22415f;
    padding: 44px 50px;
  }
}
@media (max-width: 1024px) {
  .xan-blue {
    background-color: #22415f;
    padding: 44px 30px;
  }
}

/* --- Transformative digital --- */

.tmhr-img {
  margin-top: 140px;
  padding: 0 20px 40px 20px !important;
}

.tm-hr-img {
  padding: 0 20px 0 0 !important;
}
@media (max-width: 767px) {
  .tm-hr-img {
    padding: 20px 20px 0 0 !important;
  }
}

.hr-wf {
  text-align: -webkit-center;
  margin-top: 50px;
}
.hr-wf p {
  color: #000000;
  font-size: 22px;
  font-weight: 200;
  line-height: 40px;
}
@media (max-width: 1024px) {
  .cont-honey h2 {
    text-align: center;
  }
  .img-honey {
    flex-direction: column-reverse;
    align-self: center;
  }

  .hr-wf p {
    color: #000000;
    font-size: 16px;
    font-weight: 200;
    line-height: 40px;
    text-align: left;
  }
  .hr-wf {
    text-align: -webkit-center;
    margin-top: 20px;
  }
}

.hr-wf-img {
  margin-top: 80px !important;
}
.hr-wf-img0 {
  padding: 0px 0px 0px 60px;
}
.hr-wf-img1 {
  padding: 0px 60px 0px 0px;
}

@media (max-width: 1024px) {
  .hr-wf-img0 {
    padding: 0px;
  }
  .hr-wf-img1 {
    padding: 0px;
  }
}
@media (max-width: 767px) {
  .hr-wf-img0 {
    padding: 0px;
  }
  .hr-wf-img1 {
    padding: 20px 0 0 0;
  }
}

.hr-blue {
  background-color: #00b1e1;
  font-size: 22px;
  font-weight: 300;
  line-height: 32px;
  color: #ffffff;
  padding: 50px 0px 0px 0px !important;
  border-radius: 20px 20px 20px 20px;
  text-align: center;
  margin-top: 90px;
  margin-bottom: 130px;
}
.hr-blue img {
  margin-bottom: -80px;
}
@media (max-width: 767px) {
  .hr-blue {
    background-color: #00b1e1;
    font-size: 16px;
    font-weight: 300;
    line-height: 32px;
    color: #ffffff;
    padding: 50px 30px 0px 30px !important;
    border-radius: 20px 20px 20px 20px;
    text-align: center;
    margin-top: 90px;
    margin-bottom: 130px;
  }

  .foot-li {
    margin-top: 0px !important;
    padding-top: 0px;
  }
  .foot-li .col-lg-9,
  .foot-li .col-lg-3 {
    display: block;
    gap: 18px;
  }
}

@media (max-width: 767px) {
  /* Sunnybee Honey */

  .hon-sec2 {
    padding: 40px 20px !important;
  }

  .img-honey {
    flex-direction: column-reverse;
    align-self: center;
  }

  .cont-honey h2 {
    text-align: center;
    font-size: 20px;
    font-weight: 300;
    line-height: 32px;
  }

  .hon-sec3 {
    text-align: center;
    background-color: #ffc923;
    margin-top: 40px;
    margin-bottom: 30px;
  }

  .hon-sec3 img {
    max-width: 100%;
    margin-top: 0px;
  }

  .hon-sec4 {
    text-align: center;
    margin-bottom: 20px;
    padding: 0px 20px !important;
  }

  .hon-sec4 h2 {
    text-align: center;
    font-size: 20px;
    font-weight: 300;
    line-height: 32px;
    margin-bottom: 20px;
  }

  .hon-sec5 {
    margin-bottom: 10px;
  }

  /*--------------------------------------------------
  --------------------------------------------------*/

  .opportunities .career-box1 {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }
  .desc {
    margin-top: 20px !important;
  }
  .title p {
    padding-right: 0px;
    padding-bottom: 20px;
  }
  .career-box-a {
    padding-left: 12px !important;
    padding-right: 12px !important;
    margin-bottom: 0px;
  }
  .marg-lft {
    margin-left: 0px !important;
  }
  .careers .cont p {
    padding-right: 0px;
  }
  .careers {
    padding: 0 22px !important;
  }
  .careers .cont h1 {
    font-size: 32px;
    font-weight: 300;
    line-height: 40px;
    padding-right: 20px;
  }
  .careers .cont p {
    font-size: 18px;
    font-weight: 300;
    line-height: 26px;
    padding-right: 20px;
  }

  .wrapper ul.r-2 {
    top: 52%;
    left: 17%;
  }
  .wrapper ul.r-1 {
    top: 100%;
    left: -10%;
  }
  .wrapper .cont-data {
    font-size: 22px;
    font-weight: 300;
    line-height: 32px;
  }
  .menu-btn {
    right: 10px;
  }

  .form-control-input-g {
    width: 100% !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 22px !important;
    color: rgb(0, 0, 0) !important;
    opacity: 0.7;
    background-color: rgb(255, 255, 255) !important;
    padding: 13px 18px !important;
    border: 0px solid #e3e3e3 !important;
    border-radius: 0 !important;
    /* margin-top: 1rem !important; */
  }
}

@media (max-width: 991px) {
  .navbar .menu-items li .form-btn a {
    box-sizing: border-box;
    color: #fff !important;
    display: inline-block;
    font-size: 42px !important;
    font-weight: 300 !important;
    letter-spacing: 1.2px;
    line-height: 66px;
    position: relative;
    text-decoration: none !important;
    text-transform: lowercase;
    width: 100%;
  }
}
